export default class CurrencyFormatter {

    /**
     * Formats the input to the currency format (1.000.000 -> 10.000 tkr.). "-" is returned if the param is null or undefined.
     */
    static format(num: number | undefined | null) : string {
    
        if(num === undefined || num === null) {
            return "-"
        }
        
        const numberFormatter = Intl.NumberFormat('da-DK', {
            maximumFractionDigits: 0
          });
        return numberFormatter.format(num / 1000) + " tkr.";
    }
}