import { InteractionType, IPublicClientApplication } from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider
} from "@azure/msal-react";
import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./layout/Layout";
// KOMPONENETER
import Overview from "./pages/Overview";
import ProjectPage from "./pages/ProjectPage";
import { loginRequest } from "./utils/authProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#252525",
      light: "#333333",
      dark: "#1F1F1F"
    },
    secondary: {
      main: "#046DB3",
      light: "#0471BF",
      dark: "#035B99"
    },
    info: {
      main: "#828282",
      dark: "#616161",
      light: "#f5f5f5",
      contrastText: "#fff"
    },
    error: {
      main: "#B03D31",
      dark: "#70271F",
      light: "#BD4235",
      contrastText: "#fff"
    },
    success: {
      main: "#009658",
      dark: "#007041",
      light: "#00BD6E",
      contrastText: "#fff"
    }
  },

});

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {
  return (
      <MsalProvider instance={pca}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={loginRequest}
        >
          <ThemeProvider theme={theme}>
            <p>Du skal være logget ind for at se denne side.</p>
            <AuthenticatedTemplate>
              <Layout>
                <Routes>
                  <Route path="/" element={<Overview />} />
                  <Route path="/projektdetaljer/:id" element={<ProjectPage />} />
                </Routes>
              </Layout>
            </AuthenticatedTemplate>
          </ThemeProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
  );
}

export default App;
