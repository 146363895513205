import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import CloseIcon from '@mui/icons-material/Close';
import { Button, Container, Dialog, Grid, IconButton, Typography } from "@mui/material";
import { surveyResultApi } from "api/projectlearning.api";
import ConfirmationDialog from "components/improvementOverview/confirmationDialog";
import { useGetAllSurveyTemplatesData } from "hooks/surveyTemplate/getAllSurveyTemplatesHook";
import { ProjectDto } from "model/pl-api";
import GeneralContext from "pages/GeneralContext";
import React, { useContext } from "react";
import { CreateSurveyResultRequest } from "../../model/pl-api";

type SurveyTemplatePickerDialogProps = {
    project?: ProjectDto;
    surveyResultCreatedHandler: (surveyResultId: string) => void;
    isQuotationOrProjectResponsible: boolean;
}

const SurveyTemplatePickerDialog: React.FC<SurveyTemplatePickerDialogProps> = (props: SurveyTemplatePickerDialogProps) => {
    const [open, setOpen] = React.useState(false);
    const [openCreateSurveyTemplateDialogs, setOpenCreateSurveyTemplateDialogs] = React.useState<Map<string, boolean>>(new Map<string, boolean>());
    const { getSurveyTemplates, surveyTemplates } = useGetAllSurveyTemplatesData();
    const ctx = useContext(GeneralContext);

    React.useEffect(() => {
        if (surveyTemplates) {
            const openDialogBooleans: Map<string, boolean> = new Map(surveyTemplates!.map(x => [x.id!, false]))
            setOpenCreateSurveyTemplateDialogs(openDialogBooleans)
        }
    }, [surveyTemplates]);

    React.useEffect(() => {
    }, [ctx.generalContext.loading]);

    const handleClickOpen = async () => {
        ctx.setGeneralContext({ loading: true })
        Promise.all([getSurveyTemplates()]).then(() => {
        }).finally(() => {
            ctx.setGeneralContext({ loading: false });
            setOpen(true);
        })

    };

    const handleCloseDialog = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        setOpen(false);
    };

    const handleCloseButton = () => {
        setOpen(false);
    };

    /**
     * Creates the survey result and passes the id of the survey result to the parent using the handler
     */
    const createSurveyResultAsync = async (surveyTemplateId: string) => {
        setOpenCreateSurveyTemplateDialogs(new Map(openCreateSurveyTemplateDialogs.set(surveyTemplateId, false)));
        ctx.setGeneralContext({ loading: true })
        setOpen(false);

        var req = new CreateSurveyResultRequest();
        req.projectId = props?.project?.id!;
        req.surveyTemplateId = surveyTemplateId;
        surveyResultApi.post(req)
            .then((response) => {
                var surveyResultId = response.data!;
                props.surveyResultCreatedHandler(surveyResultId);
            });
    }

    return (
        <>
            <Button
                sx={{
                    mr: 1,
                    maxWidth: "250px",
                    maxHeight: "50px",
                    minWidth: "150px",
                    minHeight: "50px",
                    border: 'none'
                }}
                color="secondary"
                variant="contained"
                startIcon={<AssignmentTurnedInOutlinedIcon />}
                onClick={handleClickOpen}
                disabled={!props.isQuotationOrProjectResponsible || ctx.generalContext.loading}
            >
                Start Evaluering
            </Button>

            <Dialog
                open={open}
                onClose={handleCloseDialog}
                scroll="body"
                maxWidth="sm"
            >
                <>
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseButton}
                        disabled={ctx.generalContext.loading}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Container >
                        <Grid container direction="column" spacing={3} sx={{ mt: 1, mb: 5 }}>
                            <Grid item>
                                <Typography sx={{ textAlign: 'center', pt: 3 }}>Vælg den evaluering du ønsker at starte.</Typography>
                            </Grid>

                            {surveyTemplates ? (
                                surveyTemplates.map((x) => (
                                    <Grid key={x.id} item sm={12} display='flex' justifyContent="center" >
                                        <ConfirmationDialog
                                            content={`Er du sikker på du vil starte evalueringen med navnet "${x.name}"?`}
                                            okayText='Ja'
                                            cancelText='Nej'
                                            cancelHandler={async () => {
                                                setOpenCreateSurveyTemplateDialogs(new Map(openCreateSurveyTemplateDialogs.set(x.id!, false)))
                                            }}
                                            okayHandler={() => createSurveyResultAsync(x.id!)}
                                            open={openCreateSurveyTemplateDialogs.get(x.id!)!}
                                            button={
                                                <Button
                                                    sx={{
                                                        fontSize: 12,
                                                        width: 170,
                                                        textTransform: 'none',
                                                        mr: 1,

                                                    }}
                                                    color="primary"
                                                    variant="contained"
                                                    onClick={() => {
                                                        setOpenCreateSurveyTemplateDialogs(new Map(openCreateSurveyTemplateDialogs.set(x.id!, true)))
                                                    }}
                                                >{x.name!}</Button>
                                            }
                                        >
                                        </ConfirmationDialog>
                                    </Grid>
                                ))
                            ) : (<></>)}
                        </Grid>
                    </Container>
                </>
            </Dialog>

        </>
    );
};

export default SurveyTemplatePickerDialog;

