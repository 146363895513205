import { projectTypeApi } from "api/projectlearning.api";
import { ProjectTypeDto } from "model/pl-api";
import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";

const useGetAllProjectTypesHook = () => {
  const [types, setTypes] = useState<ProjectTypeDto[]>();
  const [isProjectTypesLoading, setIsProjectTypesLoading] = useState<boolean>(false);

  const getTypes = useCallback(async () => {
    try {
      setIsProjectTypesLoading(true);
      const typesResponse = await projectTypeApi.getAll(
        undefined,
        undefined,
        undefined
      );
      setTypes(typesResponse.data!);
    } catch (error: any) {
      console.log(error);
        const additionalProperties = {
        "ApplicationName": "Client App",
        "Hook": "useGetAllProjectTypes",
        "Error": error
      };
      appInsights.trackException({exception: new Error(error)}, additionalProperties);
    } finally {
      setIsProjectTypesLoading(false)
    }
  }, [types]);
  return {
    types,
    getTypes,
    isProjectTypesLoading
  };
};

export const useGetAllProjectTypes = useGetAllProjectTypesHook;
