import { AuthenticatedTemplate } from "@azure/msal-react";
// MUI Icons
import AppsIcon from "@mui/icons-material/Apps";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
//MUI
import Typography from "@mui/material/Typography";
import Filtration from "components/projectSorting/Filtration";
import { useGetAllProjectLearningMasterData } from "hooks/projectLearningMasterData/getAllProjectLearningMasterDataHook";
import { useGetAllParentsWithchildrenSections } from "hooks/projectSections/getAllParentsWithChildrenSectionsHook";
import { useGetAllProjectSections } from "hooks/projectSections/getAllProjectSectionsHook";
import { useGetAllProjectTypes } from "hooks/projectType/getAllProjectTypeHook";
import { useGetAllSurveyResultsData } from "hooks/surveyResult/getAllSurveyResultsHook";
import { useGetAllSurveyRootTopicsData } from "hooks/surveyRootTopic/getAllSurveyRootTopicsHook";
import { useGetAllSurveyTemplatesData } from "hooks/surveyTemplate/getAllSurveyTemplatesHook";
import { useGetSystemUserByBearerTokenData } from "hooks/systemUser/getSystemUserByBearerTokenHook";
import { ProjectDto, ProjectTypeDto, Role, SectionDto, SurveyTemplateDto } from "model/pl-api";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import ForcesBox from "../components/ForcesBox";
import HeatMap from "../components/heatmap/HeatMap";
import ImprovementBox from "../components/ImprovementBox";
// Komponenter
import PictureBox from "../components/PictureBox";
import { default as TrendMap } from "../components/trendmap/Trendmap";
// VORES EGEN STYLING
import useStyles from "../styles";
import GeneralContext from "./GeneralContext";
import {
  default as OverviewContext,
  IOverviewContext
} from "./OverviewContext";

export type FilterProps = {
  projectFilter: ProjectDto[];
  sectionFilter: SectionDto[];
  projectTypeFilter: ProjectTypeDto[];
  surveyTemplateFilter: SurveyTemplateDto[];
  rolesFilter: Role[];
  periodFromFilter: Date | undefined;
  periodToFilter: Date | undefined;
  latestForecastLowFilter: number | undefined;
  latestForecastHighFilter: number | undefined;
};

export type SurveyProps = {
  projectNames: ProjectDto[];
  sectionNames: SectionDto[];
  projectTypes: ProjectTypeDto[];
  surveyTemplates: SurveyTemplateDto[];
  roles: Role[];
};

const Overview: React.FC<any> = () => {
  const { getUser, user } = useGetSystemUserByBearerTokenData();
  const [defaultFilterBool, setDefaultFilterBool] = useState<boolean>(false);
  const [filterUpdated, setFilterUpdated] = useState<boolean>(false);
  const [active, setActive] = useState("Heatmap");
  const classes = useStyles();

  let filterProps: FilterProps = {
    projectFilter: [],
    sectionFilter: [],
    projectTypeFilter: [],
    surveyTemplateFilter: [],
    rolesFilter: [],
    periodFromFilter: undefined,
    periodToFilter: undefined,
    latestForecastLowFilter: undefined,
    latestForecastHighFilter: undefined,
  };

  //Context + Default filtering
  const [projectFilter, setProjectFilter] = useState<FilterProps>(filterProps);

  useEffect(() => {
    setDefaultFilter();
  }, [user]);

  const context: IOverviewContext = {
    projectContext: projectFilter,
    setProjectContext: setProjectFilter,
  };
  const prjContext = useContext(GeneralContext)

  // Scroll
  const [scrollX, setScrollX] = useState<number>(window.scrollX);
  const [scrollY, setScrollY] = useState<number>(window.scrollY);

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  const { getTopics, surveyTopics, isSurveyTopicsLoading, isSuccess } = useGetAllSurveyRootTopicsData();
  const { getSurveyResults, surveyResults, isSurveyResultsLoading } = useGetAllSurveyResultsData(projectFilter);
  const { getProjects, projects, isProjectsLoading } = useGetAllProjectLearningMasterData();
  const { getSections, sections, isSectionsLoading } = useGetAllProjectSections();
  const { getParentSections, parentSections } = useGetAllParentsWithchildrenSections();
  const { getTypes, types, isProjectTypesLoading } = useGetAllProjectTypes();
  const { getSurveyTemplates, surveyTemplates, isSurveyTemplatesLoading } = useGetAllSurveyTemplatesData();


  const surveyProps: SurveyProps = {
    projectNames: Array<ProjectDto>(),
    sectionNames: Array<SectionDto>(),
    projectTypes: Array<ProjectTypeDto>(),
    surveyTemplates: Array<SurveyTemplateDto>(),
    roles: Array<Role>(),
  };

  const callOnClickSurveyResults = () => {
    prjContext.setGeneralContext({ loading: true });

    setScrollX(window.scrollX);
    setScrollY(window.scrollY);

    getSurveyResults(projectFilter)
      .then(x => prjContext.setGeneralContext({ loading: false }));
  };

  const callOnClickDefaultSurveyResults = async () => {
    prjContext.setGeneralContext({ loading: true });

    setScrollX(window.scrollX);
    setScrollY(window.scrollY);

    setDefaultFilter();
  }

  const resetFilter = () => {
      // Works well. Looks not so well.
      projectFilter.latestForecastHighFilter = undefined;
      projectFilter.latestForecastLowFilter = undefined;
      projectFilter.periodFromFilter = undefined;
      projectFilter.periodToFilter = undefined;
      projectFilter.projectFilter = [];
      projectFilter.projectTypeFilter = [];
      projectFilter.rolesFilter = [];
      projectFilter.sectionFilter = [];
      projectFilter.surveyTemplateFilter = [];
  };

  const setDefaultFilter = () => {
    if (user) {
      resetFilter();

      let lastTwoYears = new Date();
      lastTwoYears.setFullYear(lastTwoYears.getFullYear() - 2);
      projectFilter.periodFromFilter = lastTwoYears;

      if (user.portfolioManagerIn?.length! > 0) {
        // Add sections where user is Portfolio Manager to Filter
        projectFilter.sectionFilter = projectFilter.sectionFilter.concat(user.portfolioManagerIn!);

        // Check for possible child sections, and add these to the filter
        let sectionsWithChildren = parentSections?.filter(p => user.portfolioManagerIn!.some(s => s.name === p.name))!;
        let allChildAndParentSections = sectionsWithChildren.flatMap(x => x.childSections!).concat(projectFilter.sectionFilter);

        // Remove duplicates from filter
        projectFilter.sectionFilter = Array.from(new Set(allChildAndParentSections.map(s => s.id)))
        .map(id => allChildAndParentSections.find(s => s.id === id)!);
      }

      if (user.sectionManagerIn?.length! > 0) {
        // Add sections where user is Section Manager to Filter
        projectFilter.sectionFilter = projectFilter.sectionFilter.concat(user.sectionManagerIn!);

        // Check for possible child sections, and add these to the filter
        let sectionsWithChildren = parentSections?.filter(p => user.sectionManagerIn!.some(s => s.name === p.name))!;
        let allChildAndParentSections = sectionsWithChildren.flatMap(x => x.childSections!).concat(projectFilter.sectionFilter);
        
        // Remove duplicates from filter
        projectFilter.sectionFilter = Array.from(new Set(allChildAndParentSections.map(s => s.id)))
        .map(id => allChildAndParentSections.find(s => s.id === id)!);
      }

      if (!user.isProjectResponsible && !user.isQuotationResponsible && user.portfolioManagerIn?.length == 0 && user.sectionManagerIn?.length == 0 && user.section) {
        projectFilter.sectionFilter = parentSections?.filter(p => p.name === user.section!.name)!;
        projectFilter.sectionFilter = projectFilter.sectionFilter!.flatMap(x => x.childSections!).concat(user.section!)
      };

      let roleList = projectFilter.rolesFilter;
      if (user.isQuotationResponsible && !roleList.includes(Role._0)) {
        roleList.push(Role._0);
      };

      if (user.isProjectResponsible && !roleList.includes(Role._1)) {
        roleList.push(Role._1);
      };

      projectFilter.rolesFilter = roleList;
    }

    setFilterUpdated(true);
    setProjectFilter(projectFilter);
    getSurveyResults(projectFilter)
    .then(x => prjContext.setGeneralContext({ loading: false }));
    setDefaultFilterBool(!defaultFilterBool);
  };

  //Call hooks on startup
  useEffect(() => {
    prjContext.setGeneralContext({ loading: true });

    Promise.all([
      getTopics(),
      getProjects(),
      getSections(),
      getParentSections(),
      getTypes(),
      getSurveyTemplates(),
      getUser()]).then(() => {
      }).finally(() => {
        prjContext.setGeneralContext({ loading: false });
      })
  }, []);


  if (surveyTemplates) {
    //Add survey templates to filter
    surveyTemplates.forEach((template) => {
      surveyProps.surveyTemplates.push(template);
    })
  }

  if (sections) {
    //Add sections to filter
    sections.forEach((section) => {
      surveyProps.sectionNames.push(section);
    });
  }

  if (types) {
    //Add types to filters
    types.forEach((type) => {
      surveyProps.projectTypes.push(type);
    });
  }

  if (projects) {
    projects.forEach((p) => {
      surveyProps.projectNames.push(p);
    });
  }

  let error = false;
  if (isSuccess === false) {
    error = true;
  }

  return (
    <AuthenticatedTemplate>
      <>
        <Typography variant="h4" gutterBottom sx={{ mb: 5, mt: 5 }}>
          Oversigt
        </Typography>
        {/* HEATMAP / TREND KNAPPER */}
        <OverviewContext.Provider value={context}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <PictureBox />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <ForcesBox
                surveyResults={surveyResults ?? []}
                topics={surveyTopics ?? []}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <ImprovementBox
                surveyResults={surveyResults ?? []}
                topics={surveyTopics ?? []}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }} alignItems="center">
            <Grid item xs={12} sm={8} display="flex" justifyContent="flex-start">
              <Typography variant="h4" gutterBottom sx={{ pt: 5 }}>
                Evalueringsresultater
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}
              display="flex"
              justifyContent="flex-end">
              <Button variant="contained" size="large" disabled={prjContext.generalContext.loading} color="primary"
                sx={{ mr: "10px" }}
                onClick={() => setActive("Heatmap")}
                startIcon={<AppsIcon />}
              >
                Heatmap
              </Button>
              <Button variant="contained" size="large" disabled={prjContext.generalContext.loading} color="primary"
                onClick={() => setActive("Trend")}
                startIcon={<TimelineIcon />}
              >
                Trend
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 5 }}>
            <Filtration
              sections={parentSections}
              surveyTemplates={surveyTemplates}
              projectTypes={types}
              projects={surveyResults?.map((v) => v.project)}
              defaultFilterLoaded={defaultFilterBool}
            />
            <Grid container sx={{ pt: 1, pb: 1 }}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                size="large"
                disabled={prjContext.generalContext.loading}
                color="primary"
                sx={{ mr: "10px" }}
                startIcon={<FilterAltIcon />}
                onClick={(e) => {
                  callOnClickDefaultSurveyResults();
                }}
              >
                Standard Filter
              </Button>
              <Button
                variant="contained"
                size="large"
                disabled={prjContext.generalContext.loading}
                color="primary"
                startIcon={<FilterAltIcon />}
                onClick={(e) => {
                  callOnClickSurveyResults();
                }}
              >
                Filtrér
              </Button>
            </Grid>
          </Grid>
          {/* TODO: Overlap loading when data */}
          <Backdrop open={prjContext.generalContext.loading} sx={{ color: '#fff' }}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <>
            {!error ?
              <Grid container>
                {active === "Heatmap" && (
                  <HeatMap
                    surveyResults={surveyResults ?? []}
                    topics={surveyTopics ?? []}
                    loading={prjContext.generalContext.loading}
                  />
                )}
                {active === "Trend" && (
                  <TrendMap
                    surveyResults={surveyResults ?? []}
                    topics={surveyTopics ?? []}
                  />
                )}
              </Grid>
              :
              <>
                <Grid>
                  <Box display="flex" justifyContent="center" minHeight="80px">
                    <Typography >
                      Ingen data fundet.
                    </Typography>
                  </Box>
                </Grid>
              </>
            }
          </>

        </OverviewContext.Provider>
      </>
    </AuthenticatedTemplate >
  );
};

export default Overview;
