import { TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeatMapTableRow = styled(TableRow)(({ theme }) => ({
  // [`&.${tableRowClasses.root}`]: {
  //   mb: 5,
  // },

  // ['&:nth-of-type(odd)']: {
  //   //backgroundColor: theme.palette.action.hover,
  //   backgroundColor: '#f6f6f6',
  //   border: 'none',
  // },
  // // hide last border
  // '&:last-child td, &:last-child th': {
  //   border: 0,
  // },
}));
