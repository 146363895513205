import { Grid } from '@mui/material';
import { SurveyResultDto, SurveyRootTopicDto } from 'model/pl-api';
import React from 'react';
// VORES EGEN STYLING
import useStyles from 'styles';
import HeatMapTable from './HeatMapTable';

export type HeatMapProps = {
  topics: SurveyRootTopicDto[];
  surveyResults: SurveyResultDto[];
  loading: boolean;
};

const HeatMap = (props: HeatMapProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <HeatMapTable
          topics={props.topics}
          surveyResults={props.surveyResults}
          loading={props.loading}
        ></HeatMapTable>
      </Grid>
    </>
  );
};

export default HeatMap;
