import { TableRow, Typography } from '@mui/material';
import { SurveyRootTopicDto } from '../../model/pl-api';
import { HeatMapTableCell } from './HeatMapTableCell';

export type HeatMapTableHeaderProps = {
  topics: SurveyRootTopicDto[];
};

export default function HeatMapTableHeader(props: HeatMapTableHeaderProps) {

  return (
    <>
      <TableRow sx={{ width: '100%' }}>
        <HeatMapTableCell
          width='20%'
          style={{
            borderRightStyle: 'solid',
            borderRightColor: '#f6f6f6',
            borderBottom: '#f6f6f6',
            borderBottomStyle: 'solid',
            borderBottomWidth: 5,
            borderWidth: 4,
            color: '#333333',
            lineHeight: 1.5,
          }}
        >
          Projekter
        </HeatMapTableCell>
        {props.topics.map((item) => (
          <HeatMapTableCell
            width={80 / props.topics.length + "%"}
            style={{
              borderRightStyle: 'solid',
              borderRightColor: '#f6f6f6',
              borderWidth: 4,
              //lineHeight: 1.2,
              borderBottom: '#f6f6f6',
              borderBottomStyle: 'solid',
              borderBottomWidth: 5,
              textAlign: 'center',
              wordBreak: 'break-all',
              padding: '10px',

            }}
            key={item.name}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: 14,

                color: '#333333',
              }}>
              {item.name}</Typography>
          </HeatMapTableCell>
        ))}
      </TableRow>
    </>
  );
}
