import { SurveyTopicResultDto } from "../../model/pl-api";

export default function getTileColouring(
  survey: SurveyTopicResultDto | undefined
) {
  if (!survey || !survey.averageRating) {
    // dvs ingen data
    return "#F5F5F5";
  } else if (
    survey.numberOfQuestionsUnderMinKpi! > 0 ||
    survey.averageRating! < survey.goalKpi!
  ) {
    //hvis KPI ikke er nået
    return "#FEBD3D";
  } else if (survey.averageRating! === 5) return "#006A4A";
  //else if (survey.averageRating! > survey.goalKpi!) return "#009758";
  // hvis KPI er nået
  else return "#00B067";
}
