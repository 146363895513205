export default function getContributionRatioColouring(
    contributionRatio: string | undefined
) {
    if (!contributionRatio) return "#707070";

    const CRNumber = contributionRatio?.split(" ", 1);
    const floatFormat = CRNumber![0].replace(",",".");
    const num = Number(floatFormat);

    if (num < 0) { return "#DF4848" } 
    else if (num <= 8) { return "#FEBD3D" } //"#FEC85D"
    else if (num > 8) { return "#00B067" };
}