import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { saveReasonActionAsCompleted } from 'hooks/surveyResult/saveReasonActionAsCompletedHook';
import { saveReasonActionAsDraft } from 'hooks/surveyResult/saveReasonActionAsDraftHook';
import GeneralContext from 'pages/GeneralContext';
import React, { useContext } from 'react';
import { ReasonActionSaveAsDraftOrCompletedRequestDto, ReasonActionSurveyQuestionResultDto, SurveyQuestionResultDto, SurveyResultDto } from '../../model/pl-api';
import ConfirmationDialog from './confirmationDialog';
import PerformCauseActionTable from './performCauseActionTable';

type PerformCauseActionDialogProps = {
    questions: SurveyQuestionResultDto[];
    surveyResult: SurveyResultDto;
    questionsUpdateHandler: () => void;
    closeWithoutSaving: () => void;
    isQuotationOrProjectResponsible: boolean;
    triggerUpdateSurveyResults: () => void;
}

const PerformCauseActionDialog: React.FC<PerformCauseActionDialogProps> = (props: PerformCauseActionDialogProps) => {
    const [questionResults, setQuestionsResults] = React.useState(props.questions);
    const [open, setOpen] = React.useState(false);
    const [openSaveAndFinishDialog, setOpenSaveAndFinishDialog] = React.useState(false);
    const [openCloseWithoutSavingDialog, setOpenCloseWithoutSavingDialog] = React.useState(false);
    const [openSaveDraftDialog, setOpenSaveDraftDialog] = React.useState(false);
    const [isSaveAndFinishEnabled, setIsSaveAndFinishEnabled] = React.useState(false);
    const ctx = useContext(GeneralContext);

    const handleClickOpen = async () => {
        setQuestionsResults(props.questions);
        setIsSaveAndFinishEnabled(saveAndFinishEnabled(props.questions))
        setOpen(true);
    };

    const tableIsUpdatedHandler = (questions: SurveyQuestionResultDto[]) => {
        setQuestionsResults(questions);
        setIsSaveAndFinishEnabled(saveAndFinishEnabled(questions))
    }

    const handleCloseDialog = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        setOpen(false);
    };

    const saveDraft = async () => {
        ctx.setGeneralContext({ loading: true });
        setOpenSaveDraftDialog(false);
        setOpen(false);

        let questionResultRequest =
            questionResults
                .map(x => {
                    let question = new ReasonActionSurveyQuestionResultDto()
                    question.surveyQuestionResultId = x.id!
                    question.reason = x.cause
                    question.action = x.action
                    return question
                });

        let request = new ReasonActionSaveAsDraftOrCompletedRequestDto();
        request.surveyResultId = props.surveyResult.id!;
        request.surveyQuestionResults = questionResultRequest;
        await saveReasonActionAsDraft(request);
        props.questionsUpdateHandler();
        props.triggerUpdateSurveyResults();
    };

    const saveAndFinishEnabled = (questions: SurveyQuestionResultDto[]): boolean => {
        const missingReasonAction = questions.some(x =>
            !x.cause || !x.action ||
            !x.cause.trim().length || !x.action.trim().length
        );
        return !missingReasonAction;
    }
    const saveAndFinish = async () => {
        ctx.setGeneralContext({ loading: true });
        setOpenSaveAndFinishDialog(false);
        setOpen(false);

        let questionResultRequest =
            questionResults
                .map(x => {
                    let question = new ReasonActionSurveyQuestionResultDto()
                    question.surveyQuestionResultId = x.id!
                    question.reason = x.cause
                    question.action = x.action
                    return question
                });

        let request = new ReasonActionSaveAsDraftOrCompletedRequestDto();
        request.surveyResultId = props.surveyResult.id!;
        request.surveyQuestionResults = questionResultRequest;
        await saveReasonActionAsCompleted(request);
        props.questionsUpdateHandler();
        props.triggerUpdateSurveyResults();
    };

    const closeWithoutSaving = async () => {
        props.closeWithoutSaving();
        setOpenCloseWithoutSavingDialog(false);
        setOpen(false);
    };

    return (
        <>
            {props.surveyResult?.statusCode?.toString() !== "ÅrsagHandlingAfsluttet" && <Button
                sx={{
                    fontSize: 12,
                    textTransform: "none",
                    maxWidth: "190px",
                    maxHeight: "40px",
                    minWidth: "190px",
                    minHeight: "40px",
                    "&:disabled": {
                        backgroundColor: '#d5e7f0'
                    },
                }}
                color="secondary"
                variant="contained"
                onClick={handleClickOpen}
                disabled={!props.isQuotationOrProjectResponsible || ctx.generalContext.loading}
            >Udfyld Årsag/Handling</Button>}
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                scroll='body'
                fullWidth={true}
                maxWidth='xl'
                disableEscapeKeyDown={true}
            >


                <Grid container sx={{ p: 4, }}>
                    <Grid item sm={12}>
                        <Box display="flex" justifyContent="flex-end">

                        </Box>
                    </Grid>
                    <Grid item sm={12} sx={{ mt: 3 }}>
                        <Box display="flex" justifyContent="flex-end">
                            <ConfirmationDialog
                                content='Vil du lukke uden at gemme dine ændringer?'
                                okayText='Ja'
                                cancelText='Nej'
                                cancelHandler={async () => setOpenCloseWithoutSavingDialog(false)}
                                okayHandler={closeWithoutSaving}
                                open={openCloseWithoutSavingDialog}
                                button={
                                    <IconButton
                                        aria-label="close"
                                        onClick={() => setOpenCloseWithoutSavingDialog(true)}
                                        disabled={ctx.generalContext.loading}
                                        sx={{
                                            position: 'absolute',
                                            right: 8,
                                            top: 8,
                                            color: (theme) => theme.palette.grey[500],
                                        }}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                }>

                            </ConfirmationDialog>
                            <ConfirmationDialog
                                content='Vil du gemme dine ændringer som en kladde?'
                                okayText='Ja'
                                cancelText='Nej'
                                cancelHandler={async () => setOpenSaveDraftDialog(false)}
                                okayHandler={saveDraft}
                                open={openSaveDraftDialog}
                                button={
                                    <Button
                                        sx={{
                                            marginRight: 1,
                                            fontSize: 12,
                                            width: 170,
                                            textTransform: 'none',
                                        }}
                                        color="info"
                                        variant="contained"
                                        onClick={() => setOpenSaveDraftDialog(true)}
                                    >Gem Kladde</Button>
                                }
                            ></ConfirmationDialog>
                            <ConfirmationDialog
                                content='Vil du gemme dine ændringer of afslutte din evaluering?'
                                okayText='Ja'
                                cancelText='Nej'
                                cancelHandler={async () => setOpenSaveAndFinishDialog(false)}
                                okayHandler={saveAndFinish}
                                open={openSaveAndFinishDialog}
                                button={
                                    <Button
                                        sx={{
                                            fontSize: 12,
                                            width: 170,
                                            textTransform: 'none',
                                            mr: 1,

                                        }}
                                        disabled={!isSaveAndFinishEnabled}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setOpenSaveAndFinishDialog(true)}
                                    >Gem og afslut</Button>
                                }
                            >
                            </ConfirmationDialog>
                        </Box>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant="h6" >
                            {props.surveyResult.name}
                        </Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Typography variant="caption" display="block">
                            Afsluttet: {props.surveyResult.completedOn?.toISOString().split('T')[0].split("-").reverse().join("-")}
                        </Typography >
                        <Typography variant="caption">Af: {props?.surveyResult?.completedBy?.name}</Typography>

                    </Grid>
                    <Grid item sm={12}>
                        <PerformCauseActionTable questions={questionResults} dataUpdatedHandler={tableIsUpdatedHandler}></PerformCauseActionTable>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
};

export default PerformCauseActionDialog;