import { TableCell, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeatMapTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.root}`]: {
    fontSize: '14px',



  },
  [`&.${tableCellClasses.head}`]: {


  },
  [`&.${tableCellClasses.body}`]: {
    borderRightStyle: "solid",
    borderRightColor: "#f6f6f6",
    borderBottom: "#f6f6f6",
    borderBottomStyle: "solid",



    // '&:first-of-type': {

    // },
  },
}));
