import { sectionApi } from "api/projectlearning.api";
import { SectionDto } from "model/pl-api";
import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";

const useGetAllParentsWithChildrenSectionsHook = () => {
  const [parentSections, setParentSection] = useState<SectionDto[]>();

  const getParentSections = useCallback(async () => {
    try {
      const sectionsReponse = await sectionApi.getAllParentsWithChildren(
        undefined,
        undefined
      );
      setParentSection(sectionsReponse.data!);
    } catch (error: any) {
      console.log(error);
      const additionalProperties = {
        ApplicationName: "Client App",
        Hook: "useGetParentsWithChildrenSections",
        Error: error,
      };
      appInsights.trackException(
        { exception: new Error(error) },
        additionalProperties
      );
    }
  }, [parentSections]);
  return {
    parentSections,
    getParentSections,
  };
};

export const useGetAllParentsWithchildrenSections =
  useGetAllParentsWithChildrenSectionsHook;
