import { createStyles, Grid, TextField, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridCellEditCommitParams, GridColumns, GridRenderCellParams, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React from 'react';
import { SurveyQuestionResultDto } from '../../model/pl-api';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '$ .css-1o9zdph-MuiDataGrid-root .MuiDataGrid-cellContent': {
        textAlign: 'center',
        color: 'red'
      },
    }
  })
);



type PerformCauseActionTableProps = {
  questions: SurveyQuestionResultDto[];
  dataUpdatedHandler: (questions: SurveyQuestionResultDto[]) => void
}

const PerformCauseActionTable: React.FC<PerformCauseActionTableProps> = (props: PerformCauseActionTableProps) => {
  type PerformCauseActionRow = {
    id: string | undefined;
    topic: string | undefined;
    question: string | undefined;
    goalKpi: number | undefined;
    rating: number | undefined;
    cause: string | undefined;
    action: string | undefined;
  }


  const columns: GridColumns = [
    {
      field: 'topic',
      headerName: 'Emne',
      width: 200,
      editable: false,
      type: 'string',
      headerClassName: 'table-header',
    },
    {
      field: 'question',
      headerName: 'Spørgsmål',
      width: 130,
      flex: 1,
      editable: false,
      type: 'string',
      headerClassName: 'table-header',
      renderCell: (params) => {
        return renderMultilineTextField(params, 4, false)
      },
      renderEditCell: (params) => {
        return renderMultilineTextFieldInputCell(params)
      }
    },
    {
      field: 'comment',
      headerName: 'Kommentar',
      width: 300,
      flex: 1,
      editable: false,
      type: 'string',
      headerClassName: 'table-header',
      renderCell: (params) => {
        return renderMultilineTextField(params, 4, false)
      },
      renderEditCell: (params) => {
        return renderMultilineTextFieldInputCell(params)
      }
    },
    {
      field: 'goalKpi',
      headerName: 'Mål KPI',
      width: 70,
      editable: false,
      type: 'number',
      headerClassName: 'table-header',
      align: 'center',
    },
    {
      field: 'rating',
      headerName: 'Score',
      width: 70,
      editable: false,
      type: 'number',
      headerClassName: 'table-header',
      align: 'center',
    },

    {
      field: 'cause',
      headerName: 'Årsag',
      width: 300,
      flex: 1,
      editable: true,
      type: 'string',
      headerClassName: 'table-header',
      renderCell: (params) => {
        return renderMultilineTextField(params, 4, true)
      },
      renderEditCell: (params) => {
        return renderMultilineTextFieldInputCell(params)
      }
    },
    {
      field: 'action',
      headerName: 'Korrigerende handling',
      width: 300,
      flex: 1,
      editable: true,
      type: 'string',
      headerClassName: 'table-header',
      renderCell: (params) => {
        return renderMultilineTextField(params, 4, true)
      },
      renderEditCell: (params) => {
        return renderMultilineTextFieldInputCell(params)
      }
    }
  ]

  const classes = useStyles();

  const rows: PerformCauseActionRow[] = props.questions.map(x => ({
    id: x.id,
    topic: x.surveyTopicResult?.name,
    question: x.question,
    goalKpi: x.surveyTopicResult?.goalKpi,
    comment: x.comment,
    rating: x.rating,
    cause: x.cause,
    action: x.action
  }))



  const renderMultilineTextField = (params: GridRenderCellParams, rows: number, editable: boolean) => {
    const { id, value, api, field } = params;
    const apiRef = useGridApiContext();

    return <TextField
      fullWidth
      multiline
      margin='normal'
      variant='standard'
      onClick={() => {
        if (editable)
          apiRef.current.setCellMode(id, field, "edit");
      }}
      InputProps={{
        disableUnderline: true,
        style: {
          fontSize: 12,
          textAlign: 'center',
        },
        readOnly: true
      }}
      rows={rows}
      value={params.value ?? ""}
    />
  }

  const renderMultilineTextFieldInputCell = (params: GridRenderEditCellParams) => {
    return <MultilineTextFieldInputCell {...params} />;
  }

  const MultilineTextFieldInputCell = (params: GridRenderEditCellParams) => {
    const { id, value, api, field } = params;
    const apiRef = useGridApiContext();

    const handleChange = async (event) => {
      apiRef.current.setEditCellValue({ id: id, field: field, value: event.target.value }, event);

      let questionIndex = props.questions.findIndex(x => x.id == id)!;
      props.questions[questionIndex][field] = event.target.value;
      props.dataUpdatedHandler(props.questions);
    };

    return (
      <TextField
        fullWidth
        multiline
        margin='normal'
        variant='standard'
        InputProps={{
          disableUnderline: true,
          style: {
            fontSize: 12,

          },
        }}
        autoFocus={true}
        inputRef={(input) => input && input.focus()}
        onFocus={(e) =>
          e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
          )}
        onBlur={(e) => {
          apiRef.current.commitCellChange({ id: id, field: field }, e)
          apiRef.current.setCellMode(id, field, "view");
        }}
        rows={4}
        value={value?.toString()}
        onChange={handleChange}
        onKeyDown={(e) => e.stopPropagation()}
      />
    );


  }

  const handleCellEditCommit = (cell: GridCellEditCommitParams) => {
    let questionIndex = props.questions.findIndex(x => x.id == cell.id)!
    props.questions[questionIndex][cell.field] = cell.value
    props.dataUpdatedHandler(props.questions)
  }

  return (

    <Grid container >
      <Grid item
        sx={{
          height: 600,
          width: 1,
          mb: 3,
          borderRight: '2px solid white',

        }}
      >
        <DataGrid
          rows={rows}
          rowHeight={100}
          columns={columns}
          hideFooter={true}
          onCellEditCommit={handleCellEditCommit}
          disableSelectionOnClick
          disableVirtualization
          sx={{
            boxShadow: 2,
            outline: 'none',
            '.MuiDataGrid-iconSeparator': {
              display: 'none'
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold"
            },
            '& .table-header': {
              backgroundColor: '#D9D9D9',
              borderRight: '2px solid white',
            },
            '& .MuiDataGrid-cell': {
              borderRight: '1px solid lightgrey',
            },


            '& .MuiDataGrid-row--lastVisible': {
              '& .MuiDataGrid-cell': {
              }
            },

            '& .MuiDataGrid-virtualScrollerContent--overflowed': {
              '.MuiDataGrid-row--lastVisible': {
                '& .MuiDataGrid-cell': {
                  borderRight: '1px solid lightgrey',
                }
              }
            },

            '.css-66dh3a-MuiInputBase-input-MuiInput-input': {

            },
          }
          }
        />

      </Grid>
    </Grid>


  )
}

export default PerformCauseActionTable