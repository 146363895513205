import { surveyResultApi } from "api/projectlearning.api";
import { SurveyResultDto } from "model/pl-api";
import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";

const useGetReasonActionOverviewHook = (projectId: string) => {
  const [surveyResults, setSurveyResults] = useState<SurveyResultDto[]>();

  const getSurveyResults = useCallback(async () => {
    try {
      const result = await surveyResultApi.getReasonActionOverviewByProjectId(
        projectId
        );
        const data = result.data!;
        const sortedData = data?.sort((e1, e2) => e2.completedOn?.getTime()! - e1.completedOn?.getTime()!);
        setSurveyResults(sortedData);
    } catch (error: any) {
      console.log(error);
        const additionalProperties = {
        "ApplicationName": "Client App",
        "Hook": "useGetReasonActionOverviewData",
        "Error": error
      };
      appInsights.trackException({exception: new Error(error)}, additionalProperties);
    }
  }, [surveyResults, projectId]);
  return {
    surveyResults,
    getSurveyResults,
  };
};

export const useGetReasonActionOverviewData = useGetReasonActionOverviewHook;
