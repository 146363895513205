import { Box, Button, Collapse, Grid, List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import ConfirmationDialog from "components/improvementOverview/confirmationDialog";
import { Paa_surveyratingrelevance, SurveyQuestionResultDto, SurveyResultDto, SurveyTopicResultDto } from "model/pl-api";
import React, { useEffect, useState } from "react";


type SurveyResultNavigationProps = {
    surveyResult: SurveyResultDto;
    selectedTopic?: SurveyTopicResultDto;
    selectedQuestion?: SurveyQuestionResultDto;
    saveDraft: () => void;
    saveAndClose: () => void;
    setSpecificQuestion: (topic: string, question: string, questionCount: number) => void;
    readonly: boolean;
    rerender: boolean;
}

const SurveyResultNavigation: React.FC<SurveyResultNavigationProps> = (props: SurveyResultNavigationProps) => {
    const [topicOpen, setTopicOpen] = useState<string>();
    const [questionSelected, setQuestionSelected] = useState<string>();
    const [isSaveAndFinishEnabled, setIsSaveAndFinishEnabled] = React.useState(false);
    const [openSaveAndFinishDialog, setOpenSaveAndFinishDialog] = React.useState(false);
    const [openSaveDraftDialog, setOpenSaveDraftDialog] = React.useState(false);

    useEffect(() => {
        setTopicOpen(props.selectedTopic?.id);
        setQuestionSelected(props.selectedQuestion?.id);
    }, [props.selectedTopic, props.selectedQuestion]);

    const handleClick = (id: string) => {
        setTopicOpen(id);
        let questionIndex = 0, count = 0, questionId = "", firstQuestion = false;
        props.surveyResult.surveyTopicResults?.forEach((t) => {
            t.surveyQuestionResults?.forEach((q) => {
                count++;
                if (t.id === id && firstQuestion === false) {
                    questionIndex = count;
                    questionId = q.id!;
                    firstQuestion = true;
                }
            })
        });
        props.setSpecificQuestion(id!, questionId, questionIndex)
    };

    const handleQuestionClick = (topic: SurveyTopicResultDto, question: SurveyQuestionResultDto) => {
        let questionIndex = 0, count = 0;
        props.surveyResult.surveyTopicResults?.forEach((t) => {
            t.surveyQuestionResults?.forEach((q) => {
                count++;
                if (q.id === question.id) questionIndex = count;
            })
        });
        props.setSpecificQuestion(topic.id!, question.id!, questionIndex)
    };

    const isQuestionUnanswered = (question: SurveyQuestionResultDto): boolean => {
        // It has a value - it is answered
        if (question.rating && !isNaN(question.rating)) return false;

        // This is what we set in frontend
        if (!question.rating && question.ratingRelevance === Paa_surveyratingrelevance._804240001) return false;

        return !question.rating && question.ratingRelevance?.toString() !== "Irrelevant" // This is what the API returns
    }

    const handleUnansweredQuestions = (topic: SurveyTopicResultDto): string => {
        let questionCount = 0;
        topic.surveyQuestionResults?.forEach((x) => {
            if (isQuestionUnanswered(x)) {
                questionCount++;
            }

        })
        return questionCount === 0 ? "" : "[ " + questionCount + " ]";
    }

    const handleTotalUnansweredQuestions = (survey: SurveyResultDto): string => {
        let unansweredQuestionCount = 0;
        survey.surveyTopicResults?.forEach((x) => x.surveyQuestionResults?.forEach((y) => {
            if (isQuestionUnanswered(y)) {
                unansweredQuestionCount++
            }
        }))
        return unansweredQuestionCount === 0 ? "" : "[ " + unansweredQuestionCount + " ]";
    };

    return (<>
        <Grid container sx={{ mt: 0, ml: 0, justifyContent: 'start' }}>
            <Grid item sx={{ zIndex: 1, backgroundColor: 'white', pl: 2, pr: 2, width: '100%' }}>
                <Box sx={{ ml: 2, mt: 3 }} >

                    <Typography variant="caption" sx={{ mb: 2, color: '#c3c3c3' }} >
                        Evaluering
                    </Typography>
                    < Typography variant="h5" display="block" gutterBottom component="div" sx={{ fontSize: 25, fontWeight: 600 }}>
                        {props.surveyResult?.name}
                        <Typography variant="h6" sx={{ float: "right", color: '#BD5E56' }}>
                            {handleTotalUnansweredQuestions(props.surveyResult)}
                        </Typography>
                    </Typography>
                    < Typography variant="caption" display="block">
                        Projekt: {props.surveyResult?.project?.name}
                    </Typography>
                    < Typography variant="caption" display="block">
                        Sagsnr: {props.surveyResult?.project?.projectNumber}
                    </Typography>
                    < Typography variant="caption" display="block">
                        Tilbudsnr: {props.surveyResult?.project!.quoteNumber}
                    </Typography>
                </Box>
            </Grid>
            <Grid item sx={{ zIndex: 1, backgroundColor: 'white', pl: 2, width: '100%' }}>
                {!props.readonly ? <Grid container spacing={1} sx={{ pt: 1, justifyContent: 'center' }}>

                    <Grid item sm={'auto'} sx={{}} >
                        <ConfirmationDialog
                            content='Vil du gemme dine ændringer som en kladde?'
                            okayText='Ja'
                            cancelText='Nej'
                            cancelHandler={async () => setOpenSaveDraftDialog(false)}
                            okayHandler={async () => {
                                setOpenSaveDraftDialog(false)
                                props.saveDraft()
                            }}
                            open={openSaveDraftDialog}
                            button={
                                <Button
                                    sx={{

                                        width: 120,
                                        fontSize: 12,
                                        textTransform: 'none',
                                        backgroundColor: '#575757'
                                    }}

                                    variant="contained"
                                    disabled={props.readonly}
                                    onClick={() => setOpenSaveDraftDialog(true)}
                                >Gem Kladde</Button>
                            }
                        ></ConfirmationDialog>
                    </Grid>
                    <Grid item sm={'auto'} sx={{ pt: 1 }} >
                        <ConfirmationDialog
                            content='Vil du gemme dine ændringer og afslutte din evaluering?'
                            okayText='Ja'
                            cancelText='Nej'
                            cancelHandler={async () => setOpenSaveAndFinishDialog(false)}
                            okayHandler={async () => {
                                setOpenSaveAndFinishDialog(false)
                                props.saveAndClose();
                            }}
                            open={openSaveAndFinishDialog}
                            button={
                                <Button
                                    sx={{
                                        fontSize: 12,
                                        width: 120,
                                        textTransform: 'none',



                                    }}
                                    disabled={
                                        props.surveyResult?.surveyTopicResults?.some(x =>
                                            x.surveyQuestionResults?.some(x =>
                                                !x.rating && (x.ratingRelevance == Paa_surveyratingrelevance._804240000 || x.ratingRelevance?.toString() == "Relevant")))
                                    }
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        const anyUnansweredQuestions = props.surveyResult?.surveyTopicResults?.some(x =>
                                            x.surveyQuestionResults?.some(x =>
                                                !x.rating && (x.ratingRelevance == Paa_surveyratingrelevance._804240000 || x.ratingRelevance?.toString() == "Relevant")))

                                        setOpenSaveAndFinishDialog(!anyUnansweredQuestions)
                                    }}
                                >Gem og afslut</Button>
                            }
                        >
                        </ConfirmationDialog>
                    </Grid>

                </Grid > : <></>}
            </Grid>


            <Grid item sx={{ zIndex: 1, backgroundColor: 'white', pl: 2, width: '100%' }}>
                <Box >
                    <List >
                        {props.surveyResult?.surveyTopicResults?.map((x) => (
                            <ListItem disablePadding key={x.id} style={{ display: 'list-item', justifyContent: 'flex-start', fontSize: 14 }}>
                                <ListItemButton
                                    key={x.id + "t"}
                                    onClick={() => { handleClick(x.id!); }}>
                                    <ListItemText
                                        key={x.id + "t1"}>

                                        <Typography sx={{ fontWeight: 'bold' }}>{x.name}</Typography>

                                    </ListItemText>
                                    {props.readonly ?
                                        <ListItemText
                                            key={x.id + "t2"}
                                            disableTypography
                                            sx={{ display: 'flex', justifyContent: 'flex-end' }}
                                        >
                                            <Typography style={{ color: 'grey', fontSize: 10 }}>{`( Mål: ${x.goalKpi ? x.goalKpi : '-'}, Min: ${x.minKpi ? x.minKpi : '-'} )`}</Typography>

                                        </ListItemText>
                                        :
                                        <ListItemText
                                            key={x.id + "t2"}
                                            style={{ display: 'flex', justifyContent: 'flex-end', color: '#BD5E56', }}>
                                            {handleUnansweredQuestions(x)}
                                        </ListItemText>}

                                    {/* {topicOpen === x.id ? <ExpandLess /> : <ExpandMore />} */}
                                </ListItemButton>
                                <Collapse in={topicOpen === x.id!} timeout="auto" unmountOnExit>
                                    <List
                                        sx={{
                                            // selected and (selected + hover) states
                                            '&& .Mui-selected, && .Mui-selected:hover': {
                                                fontWeight: 600
                                            },
                                        }}
                                    >
                                        {x.surveyQuestionResults?.map((y) => (
                                            <ListItem key={y.id + "Q"} >
                                                <ListItemButton

                                                    selected={questionSelected === y.id!}
                                                    key={y.id + "Q1"}
                                                    onClick={() => { handleQuestionClick(x, y); }}>
                                                    <ListItemText
                                                        key={y.id + "Q2"}
                                                        sx={{ pl: 4, color: isQuestionUnanswered(y) ? '#BD5E56' : '' }}
                                                        primary={y.name}>
                                                    </ListItemText>

                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Grid>
            <Grid item sx={{ zIndex: 1, backgroundColor: 'white', pl: 2, pb: 2, width: '100%' }}>
                {!props.readonly ? <Grid container spacing={1} sx={{ justifyContent: 'center' }}>

                    <Grid item sm={'auto'} sx={{}} >
                        <ConfirmationDialog
                            content='Vil du gemme dine ændringer som en kladde?'
                            okayText='Ja'
                            cancelText='Nej'
                            cancelHandler={async () => setOpenSaveDraftDialog(false)}
                            okayHandler={async () => {
                                setOpenSaveDraftDialog(false)
                                props.saveDraft()
                            }}
                            open={openSaveDraftDialog}
                            button={
                                <Button
                                    sx={{

                                        width: 120,
                                        fontSize: 12,
                                        textTransform: 'none',
                                        backgroundColor: '#575757'
                                    }}

                                    variant="contained"
                                    disabled={props.readonly}
                                    onClick={() => setOpenSaveDraftDialog(true)}
                                >Gem Kladde</Button>
                            }
                        ></ConfirmationDialog>
                    </Grid>
                    <Grid item sm={'auto'} sx={{ pt: 1 }} >
                        <ConfirmationDialog
                            content='Vil du gemme dine ændringer og afslutte din evaluering?'
                            okayText='Ja'
                            cancelText='Nej'
                            cancelHandler={async () => setOpenSaveAndFinishDialog(false)}
                            okayHandler={async () => {
                                setOpenSaveAndFinishDialog(false)
                                props.saveAndClose();
                            }}
                            open={openSaveAndFinishDialog}
                            button={
                                <Button
                                    sx={{
                                        fontSize: 12,
                                        width: 120,
                                        textTransform: 'none',



                                    }}
                                    disabled={
                                        props.surveyResult?.surveyTopicResults?.some(x =>
                                            x.surveyQuestionResults?.some(x =>
                                                !x.rating && (x.ratingRelevance == Paa_surveyratingrelevance._804240000 || x.ratingRelevance?.toString() == "Relevant")))
                                    }
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        const anyUnansweredQuestions = props.surveyResult?.surveyTopicResults?.some(x =>
                                            x.surveyQuestionResults?.some(x =>
                                                !x.rating && (x.ratingRelevance == Paa_surveyratingrelevance._804240000 || x.ratingRelevance?.toString() == "Relevant")))

                                        setOpenSaveAndFinishDialog(!anyUnansweredQuestions)
                                    }}
                                >Gem og afslut</Button>
                            }
                        >
                        </ConfirmationDialog>
                    </Grid>

                </Grid > : <></>}
            </Grid>

        </Grid>
    </>)
}

export default SurveyResultNavigation;