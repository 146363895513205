import { surveyResultApi } from "api/projectlearning.api";
import { SurveyResultSaveAsDraftRequestDto } from "model/pl-api";
import { appInsights } from "utils/logging/appInsights";

export const saveSurveyAsDraft = async (
  requestDto: SurveyResultSaveAsDraftRequestDto
) => {
  try {
    let x = await surveyResultApi.saveSurveyAsDraft(requestDto);
  } catch (error: any) {
    console.log(error);
      const additionalProperties = {
      "ApplicationName": "Client App",
      "Request": "saveSurveyAsDraft",
      "Error": error
    };
    appInsights.trackException({exception: new Error(error)}, additionalProperties);
  }
};
