import React from 'react';
import image from '../assets/images/aarsleff_rail.png';
// VORES EGEN STYLING
import useStyles from '../styles';



const PictureBox: React.FC<any> = () => {
  const classes = useStyles();
  return (
    <>

      <img src={image} className={classes.imageRail} />
    </>
  )
};

export default PictureBox;