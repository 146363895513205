import { Theme } from "@mui/material";
// MUI
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { ProjectDto } from "model/pl-api";
import React from "react";
import ContributionRatioFormatter from "utils/formatters/ContributionRatioFormatter";
import CurrencyFormatter from "utils/formatters/CurrencyFormatter";
import getContributionRatioColouring from "utils/styling/ContributionRatioColouring";

//  List / Links / Data
const budget = [
  {
    text: "Kalkulationsbudget",
    data: "",
    dg: "",
  },
  {
    text: "Produktionsbudget",
    data: "",
    dg: "",
  },
  {
    text: "Seneste Prognose",
    data: "",
    dg: "",
  },
];

const useStyles = makeStyles((theme: Theme) => {
  return {
    listItemTextMove: {
      // display: "flex",
      // justifyContent: "flex-start",

    },
  };
});

type projectPageBudgetProps = {
  project: ProjectDto;
};

const ProjectPageBudget: React.FC<any> = (props: projectPageBudgetProps) => {
  const classes = useStyles();


  //Kalkulationsbudget
  budget[0].data! = CurrencyFormatter.format(props.project?.calculationBudget);
  budget[0].dg! = ContributionRatioFormatter.format(props.project?.calculationBudgetContributionRatio);

  //Produktionsbudget
  budget[1].data! = CurrencyFormatter.format(props.project?.productionBudget);
  budget[1].dg! = ContributionRatioFormatter.format(props.project?.productionBudgetContributionRatio);

  //Seneste Prognose
  budget[2].data! = CurrencyFormatter.format(props.project?.latestForecast);
  budget[2].dg! = ContributionRatioFormatter.format(props.project?.latestForecastContributionRatio);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: "background.paper",
          pb: 2,
        }}
      >
        <nav aria-label="Data box projectpage">
          <Typography variant="h6" gutterBottom sx={{ pt: 4, pl: 2 }}>
            Budget
          </Typography>

          {/* List / Links / Data  */}
          <List>
            {budget.map((item, index) => (
              <React.Fragment key={index + "wrapper"}>
                <ListItem key={index}>
                  <ListItemText primary={item.text} key={index + "listitem1"} sx={{ textAlign: 'left', mr: 0, pr: 10, width: '70%' }} />
                  <ListItemText key={index + "listitem2"} sx={{ width: '30%' }}
                    // primary={"OMS: " + item.data}
                    // secondary={"DG: " + item.dg}
                    primary={
                      <Typography
                        sx={{ display: 'block' }}
                        component="span"
                        variant="body2"
                      >
                        OMS: {item.data}
                      </Typography>}
                    secondary={
                      <Typography
                        component="span"
                        variant="caption"
                        color="#707070"
                      >
                        {"DG: "}
                        <Typography
                          component="span"
                          variant="caption"
                          sx={{ color: getContributionRatioColouring(item.dg) }}
                        >
                          {item.dg}
                        </Typography>
                      </Typography>
                    }
                  />
                </ListItem>
                {budget.length - 1 === index || <Divider variant="middle" />}
              </React.Fragment>
            ))}
          </List>
        </nav>
      </Box>
    </>
  );
};

export default ProjectPageBudget;
