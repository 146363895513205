import {
  Paa_surveyratingrelevance,
  SurveyQuestionResultCompletedRequest,
  SurveyQuestionResultDraftRequest, SurveyQuestionResultDto
} from "model/pl-api";

export default class SurveyQuestionMapper {
  /**
   * Maps from SurveyQuestionResultDto to SurveyQuestionResultCompletedRequest.
   */
  static MapSurveyQuestionResultToSurveyQuestionResultCompletedRequest(
    survey: SurveyQuestionResultDto
  ): SurveyQuestionResultCompletedRequest {
    let req = new SurveyQuestionResultCompletedRequest();
    req.comment = survey.comment;
    req.rating = survey.rating
    req.goodIdea = survey.goodIdea;
    req.surveyQuestionResultId = survey.id!;

    if(survey.ratingRelevance == Paa_surveyratingrelevance._804240000 || survey.ratingRelevance?.toString() == "Relevant")
        req.relevance = true;

    if(survey.ratingRelevance == Paa_surveyratingrelevance._804240001 || survey.ratingRelevance?.toString() == "Irrelevant")
        req.relevance = false;
    
    return req;
  }

  /**
   * Maps from SurveyQuestionResultDto to SurveyQuestionResultDraftRequest.
   */
  static MapSurveyQuestionResultToSurveyQuestionResultDraftRequest(
    survey: SurveyQuestionResultDto
  ): SurveyQuestionResultDraftRequest {
    let req = new SurveyQuestionResultDraftRequest();
    req.comment = survey.comment;
    req.rating = survey.rating
    req.goodIdea = survey.goodIdea;
    req.surveyQuestionResultId = survey.id!;

    if(survey.ratingRelevance == Paa_surveyratingrelevance._804240000 || survey.ratingRelevance?.toString() == "Relevant")
      req.relevance = true;

    if(survey.ratingRelevance == Paa_surveyratingrelevance._804240001 || survey.ratingRelevance?.toString() == "Irrelevant")
      req.relevance = false;
      
    return req;
  }
}
