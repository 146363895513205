import {
    Table,
    TableBody,
    TableContainer,
    TableHead
} from "@mui/material";
import {
    SurveyResultDto, SurveyRootTopicDto
} from "model/pl-api";
import { useMemo } from "react";
import ProjectHeatMapTableBody from "./ProjectHeatMapTableBody";
import ProjectHeatMapTableHeader from "./ProjectHeatMapTableHeader";

export type ProjectHeatMapTableProps = {
    topics: SurveyRootTopicDto[];
    surveyResults: SurveyResultDto[];
    onClickHandler: (id: string) => void;
    isQuotationOrProjectResponsible: boolean;
};

export default function ProjectHeatMapTable(props: ProjectHeatMapTableProps) {
    const surveyResults = props.surveyResults.sort((e1, e2) => {
        const status1 = e1.statusCode?.toString().toLowerCase();
        const status2 = e2.statusCode?.toString().toLowerCase();

        if (status1 == "afventerbesvarelse") return -1;
        if (status2 == "afventerbesvarelse") return 1;
        return (e2.completedOn?.getTime()! - e1.completedOn?.getTime()!)
    });

    const orderedTopics = useMemo(() => {
        if (!props.topics) {
            return [];
        }
        const topicsOrdered: SurveyRootTopicDto[] = props.topics.sort((a, b) =>
            (a.position ?? 0) > (b.position ?? 0) ? 1 : -1
        );
        return topicsOrdered;
    }, [props.topics]);

    return (
        <TableContainer >
            <Table aria-label="customized table" sx={{ width: '100%' }}>
                <TableHead sx={{ backgroundColor: "white" }}>
                    <ProjectHeatMapTableHeader topics={orderedTopics}></ProjectHeatMapTableHeader>

                </TableHead>
                <TableBody sx={{}}>
                    <ProjectHeatMapTableBody
                        onClickHandler={props.onClickHandler}
                        surveyResults={surveyResults}
                        isQuotationOrProjectResponsible={props.isQuotationOrProjectResponsible}
                        topics={orderedTopics}
                    ></ProjectHeatMapTableBody>
                </TableBody>
            </Table>
        </TableContainer>
    );
}
