import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  imageRail: {
    borderRadius: 1,
    width: '100%',
    height: '100%',
  },
  checkboxDropdown: {
    backgroundColor: '#ffffff',
  },
  dropdown: {
    width: '100%',
    marginRight: 5,
  },

  projectHeatmapBox: {
    backgroundColor: '#ffffff',
    height: 50,
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    marginRight: 5,
  },
  heatmapBox: {
    backgroundColor: '#ffffff',
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 'bold',
    padding: 5,
    marginRight: 5,
  },
  projectCard: {
    borderRadius: 1,
  },
  projectCardFontSize: {
    fontSize: 7,
  },
  projectCardIcon: {
    fontSize: 'small',
    marginLeft: 150,
  },
  dataSquare: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 'bold',
    color: '#ffffff',
    marginRight: 5,
  },
  dateInputBox: {
    height: 50,
  },
  enterpriseSorting: {
    padding: 20,
    width: 200,
  },

  trendmap: {},
  chartBox: {
    backgroundColor: '#ffffff',
    width: 1500,
    [theme.breakpoints.down('md')]: {
      width: 800,
    },
  },
  background: {
    backgroundColor: '#ffffff',
  },
}));

export default useStyles;
