import { surveyResultApi } from "api/projectlearning.api";
import { SurveyResultDto } from "model/pl-api";
import { FilterProps } from "pages/Overview";
import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";

const useGetAllSurveyResultsHook = (props: FilterProps) => {
  const [surveyResults, setsurveyResults] = useState<SurveyResultDto[]>();
  const [isSurveyResultsLoading, setIsSurveyResultsLoading] = useState<boolean>(false);
  
  const getSurveyResults = useCallback(
    async (filter: FilterProps) => {
      try {
        setIsSurveyResultsLoading(true);
        const surveyResultsResponse = await surveyResultApi.getAll(
          undefined,
          filter.sectionFilter.map((s) => s.id!),
          filter.projectTypeFilter.map((s) => s.id!),
          filter.surveyTemplateFilter.map((s) => s.id!),
          filter.rolesFilter,
          filter.periodFromFilter,
          filter.periodToFilter,
          filter.latestForecastLowFilter,
          filter.latestForecastHighFilter
        );
        setsurveyResults(surveyResultsResponse.data!);
      } catch (error: any) {
        console.log(error);
          const additionalProperties = {
          "ApplicationName": "Client App",
          "Hook": "useGetAllSurveyResultsData",
          "Error": error
        };
        appInsights.trackException({exception: new Error(error)}, additionalProperties);
      } finally {
        setIsSurveyResultsLoading(false);
      }
    },
    [surveyResults]
  );
  return {
    surveyResults,
    getSurveyResults,
    isSurveyResultsLoading,
  };
};

export const useGetAllSurveyResultsData = useGetAllSurveyResultsHook;
