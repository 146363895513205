import { SurveyResultDto, SurveyRootTopicDto } from 'model/pl-api';

export type ForcesAndImprovementProps = {
  topics: SurveyRootTopicDto[];
  surveyResults: SurveyResultDto[];
}

export function forcesAndImprovementsCalculation(props: ForcesAndImprovementProps, reverseSort: boolean = false) {
  return props.topics?.map(t => {
    return {
      name: t.name,
      key: t.position,
      data: props.surveyResults.map(sr => sr.surveyTopicResults!.find(str => str.surveyRootTopic!.id == t.id)?.averageRating)
    }
  })?.map(t => {
    return {
      name: t.name,
      key: t.key,
      value: calculateAverage(t.data)
    }
  })
    .sort((a, b) => (a.value - b.value) * (reverseSort ? -1 : 1))
    .filter(x => x.value !== 0);
}

function calculateAverage(ratings: (number | undefined)[]): number {
  if (ratings === undefined || ratings === null) {
    return 0;
  }

  let ratingsFiltered = ratings.filter(x => x !== undefined && x !== null);

  if (ratingsFiltered.length === 0) {
    return 0;
  }

  let sum = ratings.reduce((a, b) => a! + b!)!;
  let average = sum / ratingsFiltered.length;
  return average;
}
