import { Card, CardContent, Grid, Typography } from '@mui/material';
import { SurveyResultDto } from 'model/pl-api';
import SurveyStatusFormatter from 'utils/formatters/SurveyStatusFormatter';
import getStatusCardColouring from './ProjectHeatMapStatusCardColouring';

export type ProjectHeatMapStatusCardProps = {
  surveyResult: SurveyResultDto;
};

const ProjectHeatMapStatusCard = (props: ProjectHeatMapStatusCardProps) => {
  return (
    <>
      <Card sx={{
        boxShadow: 0, borderRadius: 'none', height: '100px', padding: 0, backgroundColor: getStatusCardColouring(props.surveyResult)
      }}>
        <Grid container columnSpacing={1}>
          <Grid item md={12} lg={12} xl={12} sx={{ mt: 1, }}>
            <CardContent sx={{
              justifyContent: 'center',
              alignContent: 'center',

              m: 0
            }}>
              <Typography sx={{
                fontSize: 14, whiteSpace: 'pre-line', textAlign: 'center'
              }} gutterBottom variant="h6">
                {SurveyStatusFormatter.format(props.surveyResult.statusCode?.toString())}
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default ProjectHeatMapStatusCard;
