import { SurveyResultDto, SurveyRootTopicDto } from "model/pl-api";
import OneDigitFormatter from "utils/formatters/OneDigitFormatter";
import { HeatMapTableCell } from "../HeatMapTableCell";
import { HeatMapTableRow } from "../HeatMapTableRow";
import getTileColouring from "../HeatMapTileColouring";
import ProjectHeatMapStatusCard from "./ProjectHeatMapStatusCard";
import ProjectHeatMapSurveyResultCard from "./ProjectHeatMapSurveyResultCard";

export type ProjectHeatMapTableBodyProps = {
    surveyResults: SurveyResultDto[];
    onClickHandler: (id: string) => void;
    isQuotationOrProjectResponsible: boolean;
    topics: SurveyRootTopicDto[];
};

export default function ProjectHeatMapTableBody(props: ProjectHeatMapTableBodyProps) {
    // Split - sort surveyresults that are yet to be completed separately, and attach them to the end of the list.
    let noCompletedOn = props.surveyResults.filter(sr => sr.completedOn == null);
    let withCompletedOn = props.surveyResults.filter(sr => sr.completedOn != null);

    noCompletedOn.sort((a, b) => b.surveyTemplate!.position! - a.surveyTemplate!.position!);
    withCompletedOn.sort((a, b) => b.completedOn!.getTime() - a.completedOn!.getTime());

    const sortedSurveyResults = noCompletedOn.concat(withCompletedOn);
    return (
        <>
            {sortedSurveyResults.map((surveyResultItem) => (
                <HeatMapTableRow key={surveyResultItem.id} sx={{ mb: 2 }}>
                    <HeatMapTableCell component="th" sx={{ padding: 0 }} scope="row" key={surveyResultItem.id + "C"}>
                        <ProjectHeatMapSurveyResultCard
                            onClickHandler={props.onClickHandler}
                            surveyResult={surveyResultItem}
                            isQuotationOrProjectResponsible={props.isQuotationOrProjectResponsible} />
                    </HeatMapTableCell>
                    {props.topics
                        .map((rootTopic) => {
                            const surveyTopicResult = surveyResultItem.surveyTopicResults
                                ?.find((topicResult) => (topicResult?.surveyRootTopic?.id === rootTopic.id))

                            return (
                                <HeatMapTableCell
                                    key={surveyTopicResult?.id + "C1"}
                                    sx={{
                                        bgcolor: getTileColouring(surveyTopicResult),
                                        textAlign: 'center',
                                        color: 'white',
                                        fontSize: 16,
                                        fontWeight: 600
                                    }}
                                >
                                    {OneDigitFormatter.format(surveyTopicResult?.averageRating)}
                                </HeatMapTableCell>
                            )
                        })}
                    <HeatMapTableCell component="th" sx={{ padding: 0 }} scope="row" key={surveyResultItem + "C2"}>
                        <ProjectHeatMapStatusCard surveyResult={surveyResultItem} />
                    </HeatMapTableCell>
                </HeatMapTableRow>
            ))}
        </>
    );
}
