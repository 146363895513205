import { projectApi } from "api/projectlearning.api";
import { ProjectDto } from "model/pl-api";
import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";

const useGetProjectLearningMasterDataHook = (projectId: string) => {
  const [project, setProject] = useState<ProjectDto>();
  const [isProjectLoading, setIsProjectLoading] = useState<boolean>(false);

  const getProject = useCallback(async () => {
    try {
      setIsProjectLoading(true);
      const project = await projectApi.get(projectId);
      if (project.data) {
        setProject(project.data!);
      }
    } catch (error: any) {
      console.log(error);
        const additionalProperties = {
        "ApplicationName": "Client App",
        "Hook": "useGetProjectLearningMasterData",
        "Error": error
      };
      appInsights.trackException({exception: new Error(error)}, additionalProperties);
    } finally {
      setIsProjectLoading(false);
    }
  }, [project, projectId]);

  return { project, getProject, isProjectLoading };
};

export const useGetProjectLearningMasterData =
  useGetProjectLearningMasterDataHook;
