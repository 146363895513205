import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import daLocale from 'date-fns/locale/da';
import OverviewContext from "pages/OverviewContext";
import * as React from "react";
import { useContext, useEffect } from "react";
// VORES EGEN STYLING
import useStyles from "../../styles";
export type PeriodFiltering = {
    defaultFilterLoaded: boolean;
};

const PeriodSortingTo: React.FC<any> = (props: PeriodFiltering) => {
    const ctx = useContext(OverviewContext);
    const [dateTo, setDateTo] = React.useState<Date | null>(null);
    const classes = useStyles();

    useEffect(() => {
        const filters = ctx.projectContext;
        const periodFilter = filters.periodToFilter;
        if (periodFilter) {
            setDateTo(periodFilter);
        } else if (periodFilter === undefined) {
            setDateTo(null);
        };
    }, [props.defaultFilterLoaded]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={daLocale}>
            <DatePicker
                label="Projekt slut"
                value={dateTo}
                inputFormat="dd-MM-yyyy"
                mask="__-__-____"
                onChange={(newValue) => {
                    setDateTo(newValue);
                    const filter = ctx.projectContext;
                    newValue ? filter.periodToFilter = newValue! : filter.periodToFilter = undefined;
                    ctx.setProjectContext(filter);
                }}
                renderInput={(params) => <TextField className={classes.checkboxDropdown} {...params} sx={{ width: '100%' }} />}
            ></DatePicker>
        </LocalizationProvider>
    );
};

export default PeriodSortingTo;