import { projectApi } from 'api/projectlearning.api';
import { ProjectDto } from 'model/pl-api';
import { useCallback, useState } from 'react';
import { appInsights } from 'utils/logging/appInsights';

const useGetAllProjectLearningMasterDataHook = () => {
  const [projects, setProject] = useState<ProjectDto[]>([]);
  const [isProjectsLoading, setIsProjectsLoading] = useState<boolean>(false);
  const getProjects = useCallback(async () => {
    try {
      setIsProjectsLoading(true);
      const fetchedProjects = await projectApi.getAll(undefined);
      if (fetchedProjects.data) {
        setProject(fetchedProjects.data);
      }
    } catch (error: any) {
      console.error(error);
        const additionalProperties = {
        "ApplicationName": "Client App",
        "Hook": "useGetAllProjectLearningMasterData",
        "Error": error
      };
      appInsights.trackException({exception: new Error(error)}, additionalProperties);
    } finally {
      setIsProjectsLoading(false);
    }
  }, [projects] );
  return { 
    projects, 
    getProjects, 
    isProjectsLoading 
  };
};

export const useGetAllProjectLearningMasterData =
  useGetAllProjectLearningMasterDataHook;
