import { systemUserApi } from "api/projectlearning.api";
import { SystemUserDto } from "model/pl-api";
import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";

const useGetSystemUserByBearerTokenHook = () => {
    const [user, setUser] = useState<SystemUserDto>();
    const getUser = useCallback(
        async () => {
            try {
                const user = await systemUserApi.getByBearerToken();
                setUser(user!.data);
            } catch (error: any) {
                console.log(error);
                const additionalProperties = {
                    "ApplicationName": "Client App",
                    "Hook": "useGetSystemUserByBearerTokenData",
                    "Error": error
                  };
                  appInsights.trackException({exception: new Error(error)}, additionalProperties);
            }
        },
        [
            user
        ]
    );
    return {
        user,
        getUser
    }
};

export const useGetSystemUserByBearerTokenData =
  useGetSystemUserByBearerTokenHook;
