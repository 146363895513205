import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import { ProjectDto, SurveyResultDto } from 'model/pl-api';
import { Link } from 'react-router-dom';
import ContributionRatioFormatter from 'utils/formatters/ContributionRatioFormatter';
import CurrencyFormatter from 'utils/formatters/CurrencyFormatter';
import DateFormatter from 'utils/formatters/DateFormatter';
import SurveyStatusFormatter from 'utils/formatters/SurveyStatusFormatter';
import getContributionRatioColouring from 'utils/styling/ContributionRatioColouring';

export type HeatMapTableBodyProps = {
  project: ProjectDto;
  surveyResult: SurveyResultDto;
  loading: boolean;
};

const HeatMapProjectCard = (props: HeatMapTableBodyProps) => {
  const quoteNumber = props.project.quoteNumber ?? '-';
  const projectNumber = props.project.projectNumber ?? '-';
  const latestForecast = CurrencyFormatter.format(props.project.latestForecast);
  const contributionRatio = ContributionRatioFormatter.format(props.project.latestForecastContributionRatio);
  const completionDate = DateFormatter.formatToDashSeparator(props.surveyResult?.completedOn);

  return (
    <>
      {/* Styling af 'box' kan benyttes til Survey-felterne, men bør styles anderledes her */}

      <Card sx={{
        boxShadow: 0, borderRadius: 'none',
        '&:hover': {
          backgroundColor: '#F1F1F1',
        },
      }} key={props.project.id + "PC"} >
        <CardActionArea
          component={Link}
          to={`/projektdetaljer/${props.project.id}`}
          key={props.project.id + "PC1"}
          disabled={props.loading}
        >
          <CardContent
            key={props.project.id + "PC2"}
          >
            <Typography sx={{ fontSize: 12, whiteSpace: 'pre-line' }} gutterBottom variant="body1" key={props.project.id + "PC3"}>
              {props.project.name}
            </Typography>
            <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, fontWeight: 'bold', whiteSpace: 'pre-line', display: 'flex' }} key={props.project.id + "PC7"}>
              {SurveyStatusFormatter.format(props.surveyResult.statusCode?.toString())}
            </Typography>
            <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex', justifyContent: 'space-between' }} key={props.project.id + "PC4"}>
              {"Tilbudsnr: " + quoteNumber + ", " + "Sagsnr: " + projectNumber} < ArrowForwardIosIcon sx={{ color: '#383636', fontSize: '10px', }} />
            </Typography>
            <Typography display="span" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex' }} key={props.project.id + "PC5"}>
              {"OMS: " + latestForecast + ", DG: "}
              <Typography display="span" variant="caption" sx={{ fontSize: 10, color: getContributionRatioColouring(contributionRatio) }}>
                {contributionRatio}
              </Typography>
            </Typography>
            <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex' }} key={props.project.id + "PC6"}>
              {props.surveyResult?.name + ", " + completionDate}
            </Typography>

          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default HeatMapProjectCard;
