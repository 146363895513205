import { TableRow, Typography } from '@mui/material';
import { SurveyRootTopicDto } from 'model/pl-api';
import { HeatMapTableCell } from '../HeatMapTableCell';

export type ProjectHeatMapTableHeaderProps = {
  topics: SurveyRootTopicDto[];
};

export default function ProjectHeatMapTableHeader(props: ProjectHeatMapTableHeaderProps) {

  return (
    <>
      <TableRow sx={{ width: '100%' }}>
        <HeatMapTableCell
          width='20%'
          style={{
            borderRightStyle: 'solid',
            borderRightColor: '#f6f6f6',
            borderBottom: '#f6f6f6',
            borderBottomStyle: 'solid',
            borderBottomWidth: 5,
            borderWidth: 4,
            fontSize: 14,
            lineHeight: 1.5,
          }}
        >
          Evaluering
        </HeatMapTableCell>
        {/* HER SKAL LAVES SÅ DER KUN VISES EMNER MED INDHOLD items.length */}
        {props.topics.map((item) => (
          <HeatMapTableCell
            width={80 / (props.topics.length + 1) + "%"}
            style={{
              borderRightStyle: 'solid',
              borderRightColor: '#f6f6f6',
              borderWidth: 4,
              fontSize: 14,
              //lineHeight: 1.5,
              borderBottom: '#f6f6f6',
              borderBottomStyle: 'solid',
              borderBottomWidth: 5,
              textAlign: 'center',
              wordBreak: 'break-all'



            }}
            key={item.name}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: 14,
                padding: 0

              }}>{item.name}</Typography>
          </HeatMapTableCell>

        ))}
        <HeatMapTableCell
          width={80 / (props.topics.length + 1) + "%"}
          style={{
            borderRightStyle: 'solid',
            borderRightColor: '#f6f6f6',
            borderBottom: '#f6f6f6',
            borderBottomStyle: 'solid',
            borderBottomWidth: 5,
            borderWidth: 4,
            fontSize: 14,
            lineHeight: 1.5,
            textAlign: 'center'
          }}
        >
          Status
        </HeatMapTableCell>
      </TableRow>
    </>
  );
}
