import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
//MUI Component
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ProjectTypeDto } from "model/pl-api";
import OverviewContext from "pages/OverviewContext";
import React, { useContext, useEffect } from "react";
// VORES EGEN STYLING
import useStyles from "../../styles";
export type ProjectTypeFiltering = {
  types: ProjectTypeDto[];
  defaultFilterLoaded: boolean;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProjectTypeSorting: React.FC<ProjectTypeFiltering> = (props: ProjectTypeFiltering) => {
  const classes = useStyles();
  const ctx = useContext(OverviewContext);
  const [projectTypeName, setProjectTypeName] = React.useState<string[]>([]);

  useEffect(() => {
    const filters = ctx.projectContext;
    const type = filters.projectTypeFilter!.map((z) => z.name!);
    setProjectTypeName(type);
  }, [props.defaultFilterLoaded]);

  const handleChange = (event: SelectChangeEvent<typeof projectTypeName>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const valueArray = typeof value === "string" ? value.split(",") : value;
    setProjectTypeName(valueArray);

    const filters = ctx.projectContext;
    filters.projectTypeFilter = valueArray.map((x) => props.types.find((v) => v.name === x)!)//?.id) // <-- To get id

    ctx.setProjectContext(filters);
  };

  return (
    <div>
      <FormControl className={classes.dropdown}>
        <InputLabel id="demo-multiple-checkbox-label">Projekttype</InputLabel>
        <Select
          className={classes.checkboxDropdown}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={projectTypeName}
          onChange={handleChange}
          input={<OutlinedInput label="Projekttype" />}
          renderValue={(selected) => "Projekttyper (" + selected.length + ")"}
          MenuProps={MenuProps}
        >
          {props?.types?.sort((a, b) => a.name!.localeCompare(b.name!)).map((type) => (
            <MenuItem key={type.id!} value={type.name!}>
              <Checkbox checked={projectTypeName.indexOf(type.name!) > -1} />
              <ListItemText primary={type.name!} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default ProjectTypeSorting;
