import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  PublicClientApplication,
  RedirectRequest,
} from "@azure/msal-browser";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { getAuthority, msalConfig } from "./utils/authProvider";

export const msalInstance = new PublicClientApplication(msalConfig);

// msalInstance.addEventCallback((event: EventMessage) => {
//     const payload = event.payload as AuthenticationResult;
//     const account = payload?.account;
//     if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
//         msalInstance.setActiveAccount(account);
//     }
//     if (event.error instanceof InteractionRequiredAuthError) {
//         if (account && payload) {
//             const request: RedirectRequest = {
//                 scopes: ["openid", "profile", "User.Read"],
//                 account: account,
//                 authority: getAuthority(),
//             };
//             msalInstance.acquireTokenRedirect(request);
//         }
//         msalInstance.loginRedirect();
//     }
// })

msalInstance.addEventCallback(async (event: EventMessage) => {
  const payload = event.payload as AuthenticationResult;
  const account = payload?.account;
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    msalInstance.setActiveAccount(account);
  }
  if (event.error instanceof InteractionRequiredAuthError) {
    if (account) {
      const request: RedirectRequest = {
        scopes: [`${process.env.REACT_APP_CPMSGATEWAY_AUTH_SCOPE}`],
        account: account,
        authority: getAuthority(),
      };
      await msalInstance.acquireTokenRedirect(request);
    } else {
      await msalInstance.loginRedirect();
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App pca={msalInstance} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
