import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";
import { SurveyTemplateApi } from "../../api/projectlearning.api";
import { SurveyTemplateDto } from "../../model/pl-api";

const useGetAllSurveyTemplatesHook = () => {
    const [surveyTemplates, setSurveyTemplates] = useState<SurveyTemplateDto[]>();
    const [isSurveyTemplatesLoading, setIsSurveyTemplatesLoading] = useState<boolean>(false)
    
    const getSurveyTemplates = useCallback(async () => {
        try {
            setIsSurveyTemplatesLoading(true);
            const surveyTemplatesList = await SurveyTemplateApi.getAll();
            setSurveyTemplates(surveyTemplatesList.data!);
        }
        catch(error: any) {
            console.log(error)
            const additionalProperties = {
                "ApplicationName": "Client App",
                "Hook": "useGetAllSurveyTemplatesData",
                "Error": error
              };
              appInsights.trackException({exception: new Error(error)}, additionalProperties);
        } finally {
            setIsSurveyTemplatesLoading(false);
        }
    }, [surveyTemplates] );

    return {
        surveyTemplates,
        getSurveyTemplates,
        isSurveyTemplatesLoading
    };
};

export const useGetAllSurveyTemplatesData = useGetAllSurveyTemplatesHook;