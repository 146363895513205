import { DatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import daLocale from 'date-fns/locale/da';
import OverviewContext from "pages/OverviewContext";
import * as React from "react";
import { useContext, useEffect } from "react";
// VORES EGEN STYLING
import useStyles from "../../styles";
export type PeriodFiltering = {
  defaultFilterLoaded: boolean;
};

const PeriodSortingFrom: React.FC<any> = (props: PeriodFiltering) => {
  const ctx = useContext(OverviewContext);
  const [dateFrom, setDateFrom] = React.useState<Date | null>(null);
  const classes = useStyles();

  useEffect(() => {
    const filters = ctx.projectContext;
    const periodFilter = filters.periodFromFilter;
    if (periodFilter) {
      setDateFrom(periodFilter);
    };
  }, [props.defaultFilterLoaded]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={daLocale}>
      <DatePicker
        label="Projekt start"
        clearable
        inputFormat="dd-MM-yyyy"
        mask="__-__-____"
        value={dateFrom}
        onChange={(newValue) => {
          setDateFrom(newValue);
          const filter = ctx.projectContext;
          newValue ? filter.periodFromFilter = newValue! : filter.periodFromFilter = undefined;
          ctx.setProjectContext(filter);
        }}
        renderInput={(params) => <TextField className={classes.checkboxDropdown} {...params} sx={{ width: '100%' }} />}
      ></DatePicker>
    </LocalizationProvider>
  );
};

export default PeriodSortingFrom;
