import SurveyStatusFormatter from 'utils/formatters/SurveyStatusFormatter';
import { SurveyResultDto } from '../../../model/pl-api';

export default function getStatusCardColouring(
  surveyResult: SurveyResultDto | undefined
) {
  // ingen data
  if (!surveyResult) return '';

  // Formatér data
  let status = SurveyStatusFormatter.format(
    surveyResult.statusCode?.toString()
  );
  if (status === 'Afventer besvarelse') {
    return '#E1E1E1';
  } else if (status === 'Afventer årsag / handling') {
    return '#E1E1E1';
  } else if (status === 'Årsag / handling afsluttet') {
    return '';
  } else if (status === 'Besvarelse afsluttet') {
    return '';
  } else {
    return '';
  }
}
