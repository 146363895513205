import CloseIcon from '@mui/icons-material/Close';
import { Dialog, Grid, IconButton } from "@mui/material";
import ConfirmationDialog from 'components/improvementOverview/confirmationDialog';
import { useGetSurveyResultByIdData } from 'hooks/surveyResult/getSurveyResultByIdHook';
import { saveSurveyAsCompleted } from 'hooks/surveyResult/saveSurveyAsCompletedHook';
import { saveSurveyAsDraft } from 'hooks/surveyResult/saveSurveyAsDraftHook';
import { SurveyQuestionResultCompletedRequest, SurveyQuestionResultDraftRequest, SurveyQuestionResultDto, SurveyResultDto, SurveyResultSaveAsCompletedRequestDto, SurveyResultSaveAsDraftRequestDto, SurveyTopicResultDto } from 'model/pl-api';
import GeneralContext from 'pages/GeneralContext';
import React, { useContext, useEffect, useState } from "react";
import SurveyQuestionMapper from 'utils/mapper/SurveyQuestionMapper';
import SurveyResulContext, { ISurveyResultContext } from './surveyResultContext';
import SurveyResultForm from './surveyResultForm';
import SurveyResultNavigation from './surveyResultNavigation';

type SurveyResultDialogProps = {
    surveyResultId: string;
    open: boolean;
    handleClose: (reload?: boolean) => void;
    closeDialog: () => void;
    isQuotationOrProjectResponsible: boolean;
}

const SurveyResultDialog: React.FC<SurveyResultDialogProps> = (props: SurveyResultDialogProps) => {
    const { getSurveyResult, surveyResult } = useGetSurveyResultByIdData(props.surveyResultId);
    const [selectedQuestion, setSelectedQuestion] = useState<SurveyQuestionResultDto | undefined>();
    const [selectedTopic, setSelectedTopic] = useState<SurveyTopicResultDto | undefined>();
    const [questionCount, setQuestionCount] = useState(0);
    const [currentQuestionCount, setCurrentQuestionCount] = useState(1);
    const [rerenderNavigation, setRerenderNavigation] = useState(false);

    //Confirmation Dialog
    const [openCloseWithoutSavingDialog, setOpenCloseWithoutSavingDialog] = React.useState(false);

    //Context
    const [survey, setSurvey] = useState<SurveyResultDto | undefined>();
    const context: ISurveyResultContext = {
        surveyResultContext: survey,
        setSurveyResultContext: setSurvey,
    };
    const projectContext = useContext(GeneralContext);

    useEffect(() => {
        projectContext.setGeneralContext({ loading: true });
        Promise.all([getSurveyResult()]).then(() => {
        }).finally(() => {
            projectContext.setGeneralContext({ loading: false });
        })
    }, [props.surveyResultId, props.open]);

    useEffect(() => {
        if (surveyResult?.surveyTopicResults)
            if (surveyResult?.surveyTopicResults[0] != undefined) {
                setSelectedTopic(surveyResult?.surveyTopicResults[0] ?? undefined);
                if (surveyResult?.surveyTopicResults[0]?.surveyQuestionResults != undefined) {
                    setSelectedQuestion(surveyResult?.surveyTopicResults[0]?.surveyQuestionResults[0] ?? undefined);
                    let count = 0;
                    surveyResult?.surveyTopicResults.forEach((s) => count += s.surveyQuestionResults?.length!);
                    setQuestionCount(count);
                    setSurvey(surveyResult);
                }
            }

    }, [surveyResult])

    const handleRerenderNavigation = () => { setRerenderNavigation(!rerenderNavigation); }

    const handleConfCLose = () => {
        setOpenCloseWithoutSavingDialog(false);
    }
    const handleCloseWithoutSavingDialog = () => {
        if (isSurveyReadOnly(surveyResult?.statusCode?.toString()!)) props.closeDialog();
        else setOpenCloseWithoutSavingDialog(true);
    }

    const handleCloseDialog = (event, reason) => {
        if (reason && reason == "backdropClick" || reason == "escapeKeyDown")
            return;
        props.closeDialog()
    };

    const handleCloseButton = async () => {
        props.closeDialog()
    };

    const setSpecificQuestion = (topicId: string, questionId: string, questionCount: number) => {
        const specificTopic = surveyResult?.surveyTopicResults?.find(x => x.id === topicId);
        setSelectedTopic(specificTopic);
        setSelectedQuestion(specificTopic?.surveyQuestionResults?.find(x => x.id === questionId));
        setCurrentQuestionCount(questionCount);
    }

    const nextQuestion = () => {
        if (selectedTopic?.surveyQuestionResults && selectedQuestion) {
            const selectedQuestionIndex = selectedTopic.surveyQuestionResults.indexOf(selectedQuestion);

            //Set selectedQuestion to next question
            if (selectedQuestionIndex < selectedTopic.surveyQuestionResults.length - 1) {

                setSelectedQuestion(selectedTopic.surveyQuestionResults[selectedQuestionIndex + 1]);
                setCurrentQuestionCount(currentQuestionCount + 1);
            }
            //Set selectedTopic to next topic and set question to first in list
            else {
                if (surveyResult?.surveyTopicResults) {
                    const selectedTopicIndex = surveyResult.surveyTopicResults.indexOf(selectedTopic);
                    const newTopic = surveyResult?.surveyTopicResults[selectedTopicIndex + 1];
                    setSelectedTopic(newTopic);
                    setSelectedQuestion(newTopic.surveyQuestionResults![0]);
                    setCurrentQuestionCount(currentQuestionCount + 1);
                }
            }
        }
    }


    const prevQuestion = () => {
        if (selectedTopic?.surveyQuestionResults && selectedQuestion) {
            const selectedQuestionIndex = selectedTopic.surveyQuestionResults.indexOf(selectedQuestion);

            //Set selectedQuestion to previous question
            if (0 < selectedQuestionIndex) {

                setSelectedQuestion(selectedTopic.surveyQuestionResults[selectedQuestionIndex - 1]);
                setCurrentQuestionCount(currentQuestionCount - 1);
            }
            //Set SelectedTopic to previous topic and set question to last in list
            else {
                if (surveyResult?.surveyTopicResults) {
                    const selectedTopicIndex = surveyResult.surveyTopicResults.indexOf(selectedTopic);
                    const previousTopic = surveyResult?.surveyTopicResults[selectedTopicIndex - 1]
                    setSelectedTopic(previousTopic);
                    setSelectedQuestion(previousTopic?.surveyQuestionResults![previousTopic?.surveyQuestionResults?.length! - 1]);
                    setCurrentQuestionCount(currentQuestionCount - 1);
                }
            }
        }
    }

    //Functions
    const saveAsDraft = async () => {
        projectContext.setGeneralContext({ loading: true });
        props.closeDialog();

        let questionList = Array<SurveyQuestionResultDraftRequest>();
        survey?.surveyTopicResults?.forEach(x => x.surveyQuestionResults?.forEach(y => questionList.push(SurveyQuestionMapper.MapSurveyQuestionResultToSurveyQuestionResultDraftRequest(y))));
        let req = new SurveyResultSaveAsDraftRequestDto();
        req.surveyResultId = surveyResult?.id!
        req.surveyQuestionResults = questionList;
        await saveSurveyAsDraft(req);
        props.handleClose(true);
        projectContext.setGeneralContext({ loading: false });
    }

    const saveAndClose = async () => {
        projectContext.setGeneralContext({ loading: true });
        props.closeDialog();

        let questionList = Array<SurveyQuestionResultCompletedRequest>();
        survey?.surveyTopicResults?.forEach(x => x.surveyQuestionResults?.forEach(y => questionList.push(SurveyQuestionMapper.MapSurveyQuestionResultToSurveyQuestionResultCompletedRequest(y))));

        let req = new SurveyResultSaveAsCompletedRequestDto();
        req.surveyResultId = surveyResult?.id!
        req.surveyQuestionResults = questionList;
        await saveSurveyAsCompleted(req);
        props.handleClose(true);
        projectContext.setGeneralContext({ loading: false });
    }

    const isSurveyReadOnly = (status: string): boolean => {
        return status === "BesvarelseAfsluttet" ||
            status === "ÅrsagHandlingAfsluttet" ||
            status === "AfventerÅrsagHandling" ||
            !props.isQuotationOrProjectResponsible;
        //Tilføj check på projektansvarlig eller tilbudsansvarlig
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleCloseDialog}
            //scroll="body"
            maxWidth="xl"

            fullWidth={true}





        >
            <>
                {surveyResult && isSurveyReadOnly(surveyResult.statusCode?.toString()!) ?
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseWithoutSavingDialog}
                        disabled={projectContext.generalContext.loading}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    :

                    <ConfirmationDialog
                        content='Er du sikker på du vil lukke evalueringen uden at gemme dine ændringer?'
                        okayText='Ja'
                        cancelText='Nej'
                        cancelHandler={async () => handleConfCLose()}
                        okayHandler={handleCloseButton}
                        open={openCloseWithoutSavingDialog}
                        button={
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenCloseWithoutSavingDialog(true)}
                                sx={{
                                    position: 'absolute',
                                    right: 8,
                                    top: 8,
                                    color: (theme) => theme.palette.grey[500],
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        }>

                    </ConfirmationDialog>}
                <SurveyResulContext.Provider value={context}>
                    {survey && <Grid container sx={{ minHeight: '100vh' }}>
                        {/* Sidemenu med navigation */}
                        <Grid item sm={3} sx={{}}>
                            <SurveyResultNavigation
                                selectedTopic={selectedTopic}
                                selectedQuestion={selectedQuestion}
                                saveAndClose={saveAndClose}
                                saveDraft={saveAsDraft}
                                setSpecificQuestion={setSpecificQuestion}
                                surveyResult={survey}
                                readonly={isSurveyReadOnly(survey.statusCode?.toString()!)}
                                rerender={rerenderNavigation}
                            ></SurveyResultNavigation>
                        </Grid>
                        {/* Udfyldning af spørgsmål */}
                        <Grid item sm={9} display="flex"

                            sx={{ backgroundColor: "#ededed" }}
                        >
                            <SurveyResultForm
                                surveyQuestionResult={selectedQuestion}
                                surveyTopicResult={selectedTopic}
                                currentCount={currentQuestionCount}
                                questionCount={questionCount}
                                nextQuestion={nextQuestion}
                                prevQuestion={prevQuestion}
                                readonly={isSurveyReadOnly(survey.statusCode?.toString()!)}
                                rerenderNavigation={handleRerenderNavigation}
                            ></SurveyResultForm>
                        </Grid>
                    </Grid>}
                </SurveyResulContext.Provider>
            </>
        </Dialog >

    )
}

export default SurveyResultDialog;

