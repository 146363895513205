import { SurveyResultDto } from "model/pl-api";
import { createContext } from "react";

export interface ISurveyResultContext {
  surveyResultContext: SurveyResultDto | undefined;
  setSurveyResultContext: (SurveyResult: SurveyResultDto | undefined) => void;
}

const SurveyResulContext = createContext({} as ISurveyResultContext);

export default SurveyResulContext;
