// MUI
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ProjectDto } from 'model/pl-api';
import React from 'react';
import DateFormatter from 'utils/formatters/DateFormatter';

//  List / Links / Data
const importantDates = [
  {
    text: 'Licitationsdato',
    data: 'xx.xx.xxxx',
  },
  {
    text: 'Opstartsdato',
    data: 'xx.xx.xxxx',
  },
  {
    text: 'Afleveringsdato',
    data: 'xx.xx.xxxx',
  },
  {
    text: 'Seneste stamdata opdatering',
    data: 'xx.xx.xxxx',
  },
  {
    text: 'Seneste aktivitet',
    data: 'xx dage siden',
  },
];


type projectPageImportantDatesProps = {
  project: ProjectDto;
};

const ProjectPageImportantDates: React.FC<any> = (
  props: projectPageImportantDatesProps
) => {
  //Licitationsdato
  importantDates[0].data! = DateFormatter.formatToDashSeparator(props.project?.tenderDate);

  //Opstartsdato
  importantDates[1].data! = DateFormatter.formatToDashSeparator(props.project?.executionStartOn);

  //Afleveringsdato
  importantDates[2].data! = DateFormatter.formatToDashSeparator(props.project?.executionEndOn);

  //Seneste stamdata opdatering
  importantDates[3].data! = DateFormatter.formatToDashSeparator(props.project?.modifiedOn);

  // Seneste aktivitet
  importantDates[4].data! = DateFormatter.formatToDaysSince(props.project?.latestActivityOn);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: 'background.paper',
          pb: 2,
        }}
      >
        <nav aria-label="Data box landingpage">
          <Typography variant="h6" gutterBottom sx={{ pt: 4, pl: 2 }}>
            Vigtige datoer
          </Typography>

          {/* List / Links / Data  */}
          <List>
            {importantDates.map((item, index) => (
              <React.Fragment key={index + "wrapper"}>
                <ListItem key={index}>
                  <ListItemText key={index + "listitem1"} primary={item.text} />
                  <ListItemText key={index + "listitem2"}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                    secondary={item.data}
                  />
                </ListItem>
                {importantDates.length - 1 === index || (
                  <Divider variant="middle" />
                )}
              </React.Fragment>
            ))}
          </List>
        </nav>
      </Box>
    </>
  );
};

export default ProjectPageImportantDates;
