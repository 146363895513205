import { sectionApi } from "api/projectlearning.api";
import { SectionDto } from "model/pl-api";
import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";

const useGetAllProjectSectionsHook = () => {
  const [sections, setSection] = useState<SectionDto[]>();
  const [isSectionsLoading, setIsSectionsLoading] = useState<boolean>(false);

  const getSections = useCallback(async () => {
    try {
      setIsSectionsLoading(true);
      const sectionsReponse = await sectionApi.getAll(undefined, undefined);
      setSection(sectionsReponse.data!);
    } catch (error: any) {
      console.log(error);
      const additionalProperties = {
        ApplicationName: "Client App",
        Hook: "useGetAllProjectSections",
        Error: error,
      };
      appInsights.trackException(
        { exception: new Error(error) },
        additionalProperties
      );
    } finally {
      setIsSectionsLoading(false);
    }
  }, [sections]);
  return {
    sections,
    getSections,
    isSectionsLoading,
  };
};

export const useGetAllProjectSections = useGetAllProjectSectionsHook;
