//MUI
import Grid from "@mui/material/Grid";
import { ProjectDto, ProjectTypeDto, SectionDto, SurveyTemplateDto } from "model/pl-api";
import React from "react";
import EnterpriseSorting from "./EnterpriseSorting";
import MyRole from "./MyRole";
import PeriodSortingFrom from "./PeriodSortingFrom";
import PeriodSortingTo from "./PeriodSortingTo";
//Komponenter
import ProjectsSorting from "./ProjectsSorting";
import ProjectTypeSorting from "./ProjectTypeSorting";
import SectionSorting from "./SectionSorting";
import SurveyTemplateSorting from "./SurveyTemplateSorting";
export type SurveyFilterProps = {
  sections: SectionDto[];
  projects: ProjectDto[];
  projectTypes: ProjectTypeDto[];
  surveyTemplates: SurveyTemplateDto[];
  defaultFilterLoaded: boolean;
};

const Filtration: React.FC<any> = (props: SurveyFilterProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
        <ProjectsSorting projects={props.projects ?? []} defaultFilterLoaded={props.defaultFilterLoaded} />
      </Grid>
      <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
        <SectionSorting sections={props.sections ?? []} defaultFilterLoaded={props.defaultFilterLoaded} />
      </Grid>
      <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
        <MyRole defaultFilterLoaded={props.defaultFilterLoaded} />
      </Grid>
      <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
        <PeriodSortingFrom defaultFilterLoaded={props.defaultFilterLoaded} />
      </Grid>
      <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
        <PeriodSortingTo defaultFilterLoaded={props.defaultFilterLoaded} />
      </Grid>
      <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
        <EnterpriseSorting defaultFilterLoaded={props.defaultFilterLoaded} />
      </Grid>
      <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
        <ProjectTypeSorting types={props.projectTypes ?? []} defaultFilterLoaded={props.defaultFilterLoaded} />
      </Grid>
      <Grid item xs={12} sm={6} md={1.5} lg={1.5}>
        <SurveyTemplateSorting surveyTemplates={props.surveyTemplates ?? []} defaultFilterLoaded={props.defaultFilterLoaded} />
      </Grid>
    </Grid>
  );
};

export default Filtration;
