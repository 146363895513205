import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
//MUI Component
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Role } from "model/pl-api";
import OverviewContext from "pages/OverviewContext";
import React, { useContext, useEffect } from "react";
// VORES EGEN STYLING
import useStyles from "../../styles";
export type RoleFiltering = {
  defaultFilterLoaded: boolean;
};

const roles = ["Projektansvarlig", "Tilbudsansvarlig"]; //, "Projektdeltager"];

const MyRole: React.FC<any> = (props: RoleFiltering) => {
  const classes = useStyles();
  const ctx = useContext(OverviewContext);
  const [roleName, setRoleName] = React.useState<string[]>([]);

  useEffect(() => {
    const filters = ctx.projectContext;
    const roleFilter = filters.rolesFilter;
    const roleNames: Array<string> = [];
    roleFilter.forEach((x) => {
      if (x === Role._0) {
        roleNames.push("Tilbudsansvarlig");
      };
      if (x === Role._1) {
        roleNames.push("Projektansvarlig");
      };
    });
    setRoleName(roleNames);
  }, [props.defaultFilterLoaded]);

  const handleChange = (event: SelectChangeEvent<typeof roleName>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const valueArray = typeof value === "string" ? value.split(",") : value;
    setRoleName(valueArray);

    //Get values and set projects
    const filters = ctx.projectContext;
    const roleArray: Role[] = [];
    valueArray.forEach((x) => {
      if (x === "Tilbudsansvarlig") {
        roleArray.push(Role._0);
      };
      if (x === "Projektansvarlig") {
        roleArray.push(Role._1);
      };
    });
    filters.rolesFilter = roleArray;
    ctx.setProjectContext(filters);
  };

  return (
    <div>
      <FormControl className={classes.dropdown}>
        <InputLabel id="demo-multiple-checkbox-label">Min rolle</InputLabel>
        <Select
          className={classes.checkboxDropdown}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={roleName}
          onChange={handleChange}
          input={<OutlinedInput label="Min Rolle" />}
          renderValue={(selected) => "Roller (" + selected.length + ")"}
        >
          {roles.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={roleName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default MyRole;
