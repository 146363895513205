import Grid from '@mui/material/Grid';
import { SurveyResultDto, SurveyRootTopicDto } from 'model/pl-api';
import React from 'react';
// VORES EGEN STYLING
import useStyles from '../../styles';
//Komponenter
import TrendmapChart from './TrendmapChart';

export enum TrendMapXAxisLabel {
  Project,
  Phase
}

export type TrendMapProps = {
  topics: SurveyRootTopicDto[];
  surveyResults: SurveyResultDto[];
  xAxisLabel: TrendMapXAxisLabel;
};

const Trendmap: React.FC<any> = (props: TrendMapProps) => {
  const classes = useStyles();
  return (
    <Grid container sx={{ mt: 3 }}>
      <Grid item md={12} lg={12} className={classes.chartBox}>
        <TrendmapChart
          surveyResults={props.surveyResults ?? []}
          topics={props.topics ?? []}
          xAxisLabel={props.xAxisLabel ?? TrendMapXAxisLabel.Project}
        />
      </Grid>
    </Grid>
  );
};

export default Trendmap;
