import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { SectionDto } from "model/pl-api";
import OverviewContext from "pages/OverviewContext";
import React, { useContext, useEffect } from "react";
// VORES EGEN STYLING
import useStyles from "../../styles";

export type ProjectSectionFiltering = {
  sections: SectionDto[];
  defaultFilterLoaded: boolean;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const SectionSorting: React.FC<ProjectSectionFiltering> = (props: ProjectSectionFiltering) => {
  const classes = useStyles();
  const ctx = useContext(OverviewContext);
  const [selectedSections, setSelectedSections] = React.useState<SectionDto[]>([]);

  const handleChange = (section: SectionDto) => {
    // See if section is in list
    const parent = props.sections.find(s => s.childSections?.some(cs => cs.id === section.id))!
    const idx = selectedSections.findIndex(s => s.id === section.id)
    if (!parent && idx === -1) // Parent section - not selected
    {
      selectedSections.push(section);

      // Select child sections
      section.childSections?.forEach(cs => {
        // Only push selected section if it is not selected 
        if (!selectedSections.some(x => x.id === cs.id))
          selectedSections.push(cs);
      })
    }

    else if (!parent && idx !== -1) // Parent section - selected
    {
      selectedSections.splice(idx, 1);

      // Unselect child sections
      section.childSections?.forEach(cs => {
        const idx = selectedSections.findIndex(s => s.id === cs.id)
        selectedSections.splice(idx, 1);
      })
    }

    else if (parent && idx === -1) // Child section - not selected
    {
      selectedSections.push(section)
      // Calculate amount of overlaps between childsection and selected childsections
      const amountOverlap = selectedSections.filter(a => parent?.childSections?.some(b => a.id === b.id)).length

      // We also add parent
      if (amountOverlap === parent.childSections?.length) {
        // Only push parent section if it is not selected 
        if (!selectedSections.some(x => x.id === parent.id))
          selectedSections.push(parent);
      }
    }

    else if (parent && idx !== -1) // Child section - selected
    {
      selectedSections.splice(idx, 1);

      const parentIdx = selectedSections.findIndex(x => x.id === parent.id);
      if (parentIdx !== -1)
        selectedSections.splice(parentIdx, 1);
    }

    //Get values and set projects
    const filters = ctx.projectContext;
    filters.sectionFilter = selectedSections;
    ctx.setProjectContext(filters);
    setSelectedSections(JSON.parse(JSON.stringify(selectedSections)));
  };

  useEffect(() => {
    setSelectedSections(ctx.projectContext.sectionFilter);
  }, [props.defaultFilterLoaded]);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleExpandedChange = (sectionId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    const parent = props.sections.find(x => x.id === sectionId)
    if (parent?.childSections?.length! >= 1)
      setExpanded(isExpanded ? sectionId : false);

  };

  return (
    <FormControl className={classes.dropdown}>
      <InputLabel id="demo-multiple-checkbox-label">{(selectedSections?.length <= 0 ? "Sektioner" : "Sektioner (" + selectedSections.length + ")")}</InputLabel>
      <Select
        className={classes.checkboxDropdown}
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        MenuProps={MenuProps}
        //input={<OutlinedInput label="Sektionsledere" />}
        variant="outlined"
        label="Default Label"

      >
        {props?.sections.sort((a, b) => a.name!.localeCompare(b.name!)).map((s) => (

          <Accordion expanded={expanded == s.id!} disableGutters onChange={handleExpandedChange(s.id!)} sx={{
            '&:not(:last-child)': {
              borderBottom: 0,
            },
            '&:before': {
              display: 'none',
            },
          }}>
            <AccordionSummary
              sx={{
                margin: 0
              }}

              expandIcon={s.childSections?.length !== 0 ? <ExpandMoreIcon
                sx={{
                  pointerEvents: "auto"
                }} /> : <></>}
              aria-controls="panel1bh-content"
              id="panel1bh-header"

            >
              <FormControlLabel
                label={s.name!}
                control={

                  <Checkbox
                    checked={selectedSections.some(sec => sec.id == s.id)}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleChange(s)
                    }}
                    indeterminate={ // Set to indeterminate when some are selected
                      selectedSections.filter(a => s.childSections?.some(b => a.id === b.id)).length >= 1 &&
                      selectedSections.filter(a => s.childSections?.some(b => a.id === b.id)).length < s.childSections?.length!
                    }
                  ></Checkbox>
                }
              />
            </AccordionSummary>
            <AccordionDetails sx={{
              display: 'flex',
              flexDirection: 'column',
              ml: 3,
              pt: 0,
              pb: 0,
            }}>
              {s.childSections?.map((cs) => (
                <>
                  <FormControlLabel
                    label={cs.name!}
                    control={
                      <Checkbox
                        checked={selectedSections.some(sec => sec.id == cs.id)}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleChange(cs)
                        }} />}
                  />
                </>
              ))}
            </AccordionDetails>
          </Accordion >
        ))
        }
      </Select>


    </FormControl >
  );
};

export default SectionSorting;
