export default class DateFormatter {

    /**
     * Formats the date to use dashes as separator (dd-mm-yyyy). Returns "-" if the param is null or undefined.
     */
    static formatToDashSeparator(date: Date | undefined | null) : string {
        if(date === undefined || date === null) {
            return "-";
        }

        return date
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("-");
    }

    /**
     * Formats the date to "X days since today" (X dag(e) siden)- Retursn "-" if the param is null or undefined
     */
    static formatToDaysSince(date: Date | undefined | null) : string {
        if(date === undefined || date === null) {
            return "-";
        }

        const today = new Date();
        return (Math.round((today.getTime() - date.getTime()) / (1000 * 3600 * 24)).toString()) + " dag(e) siden";
    }
}