import { InputAdornment, InputLabel } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import OverviewContext from 'pages/OverviewContext';
import React, { useContext, useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
// VORES EGEN STYLING
import useStyles from '../../styles';

export type EnterpriseFiltering = {
    defaultFilterLoaded: boolean;
};

const NumberFormatCustom: React.FC<any> = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                });
            }}
            thousandSeparator="."
            decimalSeparator=","
            isNumericString
            allowNegative={false}

        />
    );
}

const EnterpriseSorting: React.FC<any> = (props: EnterpriseFiltering) => {
    const classes = useStyles();
    const ctx = useContext(OverviewContext);
    const [enterprises, setEnterprises] = React.useState<string[]>(["", ""]);
    const [from, setFrom] = useState<string>();
    const [to, setTo] = useState<string>();

    useEffect(() => {
        const filters = ctx.projectContext;
        const entFrom = filters.latestForecastLowFilter;
        const entTo = filters.latestForecastHighFilter;
        setFrom(entFrom?.toString() ?? "");
        setTo(entTo?.toString() ?? "");
        let v = [entFrom?.toString() ?? "", entTo?.toString() ?? ""];
        setEnterprises(v);
    }, [props.defaultFilterLoaded]);

    useEffect(() => {
        handlechange();
    }, [to, from]);

    const handlechange = () => {
        //Update states when changeing
        let v = [from ?? "", to ?? ""];
        setEnterprises(v);
        const filters = ctx.projectContext;
        //Set upper og lower boundary in filter
        let fromNumber: number | undefined = from !== undefined && from !== "" ? Number(from) * 1000 : undefined;
        let toNumber: number | undefined = to !== undefined && to !== "" ? Number(to) * 1000 : undefined;
        filters.latestForecastLowFilter = fromNumber;
        filters.latestForecastHighFilter = toNumber;
        ctx.setProjectContext(filters);
    }

    const formatNumber = (number: string): string => {
        let parts = number.toString().split(".");
        const numberPart = parts[0];
        const decimalPart = parts[1];
        const thousands = /\B(?=(\d{3})+(?!\d))/g;
        return numberPart.replace(thousands, ".") + (decimalPart ? "," + decimalPart : "");
    }

    return (
        <div >
            <FormControl className={classes.dropdown}>
                <InputLabel id="project-label">Projekt OMS</InputLabel>
                <Select className={classes.checkboxDropdown}
                    labelId="project-label"
                    id="project"
                    label='Projekt OMS'
                    multiple
                    value={enterprises.every(v => !v) ? [] : enterprises}
                    renderValue={(selected) => {
                        const from = selected[0];
                        const formattedFrom = formatNumber(from);
                        const to = selected[1];
                        const formattedTo = formatNumber(to);

                        if (from && to)
                            return formattedFrom + ' tkr.' + ' - ' + formattedTo + ' tkr.';

                        else if (from && !to)
                            return formattedFrom + ' tkr.' + ' - ';

                        else if (!from && to)
                            return ' - ' + formattedTo + ' tkr.';

                        return null;
                    }}
                >

                    <Box sx={{ p: 2, width: 200 }}>
                        <TextField sx={{ width: '100%' }}
                            id="standard-number"
                            label="Fra"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: <InputAdornment position="end">tkr.</InputAdornment>,
                            }}
                            variant="standard"
                            onChange={(e) => { setFrom(e.target.value); }}
                            value={from}

                        />
                        <TextField sx={{ mt: 4, width: '100%' }}
                            id="standard-number"
                            label="Til"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                                endAdornment: <InputAdornment position="end">tkr.</InputAdornment>,
                            }}
                            variant="standard"
                            onChange={(e) => { setTo(e.target.value); }}
                            value={to}
                        />
                    </Box>
                </Select>
            </FormControl>
        </div >
    );
}

export default EnterpriseSorting;
