import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import CloseIcon from '@mui/icons-material/Close';
import { Backdrop, Box, Button, CircularProgress, Dialog, Grid, IconButton, Typography } from "@mui/material";
// Style
import { makeStyles } from "@mui/styles";
import { useGetReasonActionOverviewData } from "hooks/surveyResult/getReasonActionOverviewHook";
import { ProjectDto } from "model/pl-api";
import GeneralContext from "pages/GeneralContext";
import React, { useContext, useState } from "react";
import ImprovementOverviewElement from "./improvementOverviewElement";

const useStyles = makeStyles((theme) => ({
  buttonImprovement: {
    backgroundColor: "white",
    color: "#333333",
    "&:hover": {
      backgroundColor: "black",
      color: "#333333",
    },
  },
}));

type ImprovementOverviewDialogProps = {
  project: ProjectDto;
  isQuotationOrProjectResponsible: boolean;
  triggerUpdateSurveyResults: () => void;
};

const ImprovementOverviewDialog: React.FC<any> = (
  props: ImprovementOverviewDialogProps
) => {
  const [open, setOpen] = useState(false);
  const { getSurveyResults, surveyResults } = useGetReasonActionOverviewData(
    props?.project?.id!
  );
  const ctx = useContext(GeneralContext);

  const handleClickOpen = async () => {
    ctx.setGeneralContext({ loading: true });
    await getSurveyResults().then(() => {
      setOpen(true)
    }).finally(() => {
      ctx.setGeneralContext({ loading: false });
    })
  };

  const questionsUpdateHandler = async () => {
    ctx.setGeneralContext({ loading: true });

    await getSurveyResults().then(() => {
    }).finally(() => {
      ctx.setGeneralContext({ loading: false });
    })
  }

  const handleCloseDialog = (event, reason) => {
    if (reason && reason == "backdropClick")
      return;
    setOpen(false);
  };

  const handleCloseButton = () => {
    setOpen(false);
  };

  const classes = useStyles();



  return (
    <>
      <Button
        className={classes.buttonImprovement}
        sx={{
          mr: 3,
          maxWidth: "250px",
          maxHeight: "50px",
          minWidth: "150px",
          minHeight: "50px",
          color: "#292929",
          boxShadow: 1
        }}
        variant="outlined"
        startIcon={<AssignmentOutlinedIcon />}
        onClick={handleClickOpen}
        disabled={ctx.generalContext.loading}
      >
        Forbedringsoversigt
      </Button>

      <Dialog
        open={open}
        onClose={handleCloseDialog}
        scroll="body"
        fullWidth={true}
        maxWidth="xl"
      >
        <Backdrop open={ctx.generalContext.loading} sx={{ color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={4} sx={{ mt: 2, mb: 5, pr: 5, pl: 5 }}>
          <Grid item sm={12}>
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                aria-label="close"
                onClick={handleCloseButton}
                disabled={ctx.generalContext.loading}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Typography variant="h6" sx={{ fontSize: 25 }}>{props?.project?.name}</Typography>
            <Typography >Tilbudsnr: {props?.project?.quoteNumber ?? '-'}</Typography>
            <Typography>Sagsnr: {props?.project?.projectNumber ?? '-'}</Typography>

          </Grid>

          {surveyResults && surveyResults.length > 0 ? (
            surveyResults.map((x) => (
              <Grid key={x.id} item sm={12}>
                <ImprovementOverviewElement
                  isQuotationOrProjectResponsible={props.isQuotationOrProjectResponsible}
                  questionsUpdateHandler={questionsUpdateHandler}
                  surveyResult={x}
                  triggerUpdateSurveyResults={props.triggerUpdateSurveyResults}
                ></ImprovementOverviewElement>
              </Grid>
            ))
          ) : (
            <>
              <Grid item sm={12}>
                <Box display="flex" justifyContent="center" minHeight="80px">
                  <Typography >
                    Projektet "{props?.project?.name!}" har ingen evalueringer hvor årsag/handling er påkrævet.
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Dialog>
    </>
  );
};

export default ImprovementOverviewDialog;
