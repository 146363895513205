import { surveyResultApi } from "api/projectlearning.api";
import { SurveyResultDto } from "model/pl-api";
import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";

const useGetSurveyResultByIdHook = (id: string) => { 
    const [surveyResult, setSurveyResult] = useState<SurveyResultDto>();

    const getSurveyResult = useCallback(
        async () => {
            try {
                const surveyResultResponse = await surveyResultApi.get(id);
                const result = surveyResultResponse.data!
               
                if (result?.surveyTopicResults) {
                    const sortedTopicResult = result?.surveyTopicResults.sort((a, b) =>
                        (a.position ?? 0) > (b.position ?? 0) ? 1 : -1!
                    );
            
                    sortedTopicResult.forEach((x) => {
                        const sortedQuestions = x.surveyQuestionResults?.sort((a, b) =>
                            (a.position ?? 0) > (b.position ?? 0) ? 1 : -1!
                        );
            
                        x.surveyQuestionResults = sortedQuestions;
                    });
                }
                setSurveyResult(result!);
            } catch (error: any) {
                console.log(error);
                const additionalProperties = {
                    "ApplicationName": "Client App",
                    "Hook": "useGetSurveyResultByIdData",
                    "Error": error
                  };
                  appInsights.trackException({exception: new Error(error)}, additionalProperties);
            }
        },
        [
            surveyResult
        ]
    );
    return {
        surveyResult,
        getSurveyResult
    }
};

export const useGetSurveyResultByIdData = useGetSurveyResultByIdHook;