// MATERIAL UI
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { HelpCenter } from '@mui/icons-material';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Grid, IconButton, Menu, MenuItem, Theme, Toolbar, Tooltip } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { makeStyles } from '@mui/styles';
import GeneralContext, { GeneralContextType, IGeneralContext } from 'pages/GeneralContext';
import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { reactPlugin } from 'utils/logging/appInsights';
//LOGO
import image from '../assets/images/aarsleff-rail-logo-250.png';
import smallImage from '../assets/images/logo-hvid.png';
// KOMPONENTER
import SearchBar from '../components/Search';






const useStyles = makeStyles((theme: Theme) => {
  return {
    page: {
      background: '#f6f6f6',
      width: '100%',
      padding: theme.spacing(3),
    },
    // root: {
    //   display: 'flex',
    // },
    active: {
      background: '#f4f4f4',
    },
    title: {
      textAlign: 'center',
      paddingTop: 70,
    },
    appBar: {
      width: '100%', //`calc(100% - ${drawerWidth}px)!important`
      height: 100,
    },
    iconColor: {},
    toolbar: theme.mixins.toolbar,
  };
});

const Layout: React.FC<any> = ({ children }) => {

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const [contextType, setContextType] = useState<GeneralContextType>({ loading: false });

  const context: IGeneralContext = {
    generalContext: contextType,
    setGeneralContext: setContextType
  };





  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {/* <div className={classes.root}> */}
      {/* app bar */}
      <GeneralContext.Provider value={context}>
        <AppBar className={classes.appBar}>
          <Toolbar >

            <Box

              component='img'
              width='20%'
              sx={{
                minWidth: '250px',
                maxWidth: '250px',
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                flexGrow: 1,
                textDecoration: 'none',
                cursor: 'pointer',
                marginTop: 2,
              }}
              alt='logo'
              src={image}
              onClick={() => { context.generalContext.loading ? '' : navigate('/') }}
            />


            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                sx={{ mt: 2 }}
              >
                <MenuIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left'
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >

                <MenuItem onClick={handleCloseNavMenu}

                >

                  <NavLink to='/'

                    style={{ textDecoration: 'none', color: '#252525' }}
                  >
                    OVERSIGT</NavLink>


                </MenuItem>

              </Menu>
            </Box>


            <Box component='img'
              sx={{
                minWidth: '50px',
                maxWidth: '50px',
                mr: 5,
                mt: 2,
                ml: 5,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                textDecoration: 'none',
                cursor: 'pointer'
              }}
              alt='logo'
              src={smallImage}
              onClick={() => { context.generalContext.loading ? '' : navigate('/') }}
            />


            <SearchBar sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, width: { xs: '50px' } }} />


            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <Button
                variant='outlined'
                startIcon={<AutoAwesomeMosaicIcon />}
                component={NavLink}
                disabled={context.generalContext.loading}
                to="/"
                sx={{
                  color: '#ffffff',
                  borderColor: '#ffffff',
                  mt: 2,
                  ml: 7,
                  '&:hover':
                  {
                    backgroundColor: '#ffffff',
                    color: '#252525'
                  }
                }}
              >
                Oversigt
              </Button>
              <Grid container justifyContent="flex-end">
                <a href="https://aarsleff.topdesk.net/tas/public/ssp/content/serviceflow?unid=b074d3c6bf1e456bafafe8e1c07cf50f&openedFromService=true">
                  <Tooltip title="Til indsendelse af fejl, ros og ændringsønsker til arbejdsgruppen" placement='bottom'>
                    <Button
                      variant='outlined'
                      startIcon={<HelpCenter />}
                      disabled={context.generalContext.loading}
                      sx={{
                        color: '#ffffff',
                        borderColor: '#ffffff',
                        mt: 2,
                        ml: 7,
                        '&:hover':
                        {
                          backgroundColor: '#ffffff',
                          color: '#252525'
                        }
                      }}
                    >
                      Feedback
                    </Button>
                  </Tooltip>
                </a>
              </Grid>
            </Box>
          </Toolbar>
        </AppBar>

        <div className={classes.page}>
          <div className={classes.toolbar}></div>
          {children}
        </div>
      </GeneralContext.Provider>
      {/* </div> */}
    </AppInsightsContext.Provider>
  );
};

export default Layout;
