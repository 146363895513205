// MUI
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ProjectDto } from 'model/pl-api';
import React from 'react';

//  List / Links / Data
const projectManager = [
  {
    text: 'Tilbudsansvarlig',
    data: 'navn efternavn',
  },
  {
    text: 'Projektansvarlig',
    data: 'navn efternavn',
  },
];

type projectPageResponsibleProps = {
  project: ProjectDto;
};

const ProjectPageResponsible: React.FC<any> = (
  props: projectPageResponsibleProps
) => {
  //Tilbudsansvarlig
  projectManager[0].data = props.project?.quotationResponsible?.name!;

  //Projektansvarlig
  projectManager[1].data = props.project?.projectResponsible?.name!;

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          bgcolor: 'background.paper',
          pb: 2,
        }}
      >
        <nav aria-label="Data box projectPage">
          <Typography variant="h6" gutterBottom sx={{ pt: 4, pl: 2 }}>
            Projektansvarlige
          </Typography>

          {/* List / Links / Data  */}
          <List>
            {projectManager.map((item, index) => (
              <React.Fragment key={index + "wrapper"}>
                <ListItem key={index}>
                  <ListItemText key={index + "listitem1"} primary={item.text} />
                  <ListItemText key={index + "listitem2"}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                    secondary={item.data ?? '-'}
                  />
                </ListItem>
                {projectManager.length - 1 === index || (
                  <Divider variant="middle" />
                )}
              </React.Fragment>
            ))}
          </List>
        </nav>
      </Box>
    </>
  );
};

export default ProjectPageResponsible;
