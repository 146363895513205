import { createContext } from "react";

export interface IGeneralContext {
    generalContext: GeneralContextType;
    setGeneralContext: (generalContext: GeneralContextType) => void;
}

export type GeneralContextType = {
    loading: boolean
};

const defaults: GeneralContextType = {
    loading: false
};

const GeneralContext = createContext({} as IGeneralContext);

export default GeneralContext;