import axios from "axios";
import { ProjectApiClient, ProjectPhaseApiClient, ProjectTypeApiClient, SectionApiClient, SurveyResultApiClient, SurveyRootTopicApiClient, SurveyTemplateApiClient, SystemUserApiClient } from "model/pl-api";
import { getUserInformation } from "../utils/getAccessToken";

const ax = axios.create({
    baseURL: process.env.REACT_APP_APIGATEWAY_BASE_URL,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

ax.interceptors.request.use(async (config) => {
    const userInformation = await getUserInformation();

    if (userInformation) {
        config!.headers!.Authorization! = `Bearer ${userInformation.bearer}`;
        config!.headers!.UserName = userInformation.userName;
    }

    return Promise.resolve(config);
});

export const projectApi = new ProjectApiClient("", ax);
export const projectPhaseApi = new ProjectPhaseApiClient("", ax);
export const projectTypeApi = new ProjectTypeApiClient("", ax);
export const sectionApi = new SectionApiClient("", ax);
export const surveyResultApi = new SurveyResultApiClient("", ax);
export const surveyRootTopicApi = new SurveyRootTopicApiClient("", ax);
export const SurveyTemplateApi = new SurveyTemplateApiClient("", ax);
export const systemUserApi = new SystemUserApiClient("", ax);
