import AppsIcon from "@mui/icons-material/Apps";
import TimelineIcon from "@mui/icons-material/Timeline";
import { Backdrop, Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ProjectHeatMapTable from 'components/heatmap/project-heatmap/ProjectHeatMapTable';
import ImprovementOverviewDialog from 'components/improvementOverview/improvementOverviewDialog';
import ProjectPageBudget from 'components/projectPage/ProjectPageBudget';
// PAGES
import ProjectPageImportantDates from 'components/projectPage/ProjectPageImportantDates';
import ProjectPageResponsible from 'components/projectPage/ProjectPageResponsible';
import SurveyResultDialog from 'components/surveyResult/surveyResultDialog';
import SurveyTemplatePickerDialog from 'components/surveyTemplatePicker/surveyTemplatePickerDialog';
import { useGetProjectLearningMasterData } from 'hooks/projectLearningMasterData/getProjectLearningMasterDataHook';
import { useGetAllSurveyRootTopicsData } from "hooks/surveyRootTopic/getAllSurveyRootTopicsHook";
import { useGetSystemUserByBearerTokenData } from 'hooks/systemUser/getSystemUserByBearerTokenHook';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { default as TrendMap, TrendMapXAxisLabel } from "../components/trendmap/Trendmap";
import useStyles from "../styles";
import GeneralContext from "./GeneralContext";

const ProjectPage: React.FC<any> = () => {
  const { id } = useParams();
  const { getProject, project, isProjectLoading } = useGetProjectLearningMasterData(id!);
  const { getUser, user } = useGetSystemUserByBearerTokenData();
  const [active, setActive] = useState("Heatmap");

  //State related to the SurveyResultDialog
  const [isSurveyResultDialogOpen, setIsSurveyResultDialogOpen] = React.useState<boolean>(false);
  const [surveyResultId, setSurveyResultId] = React.useState<string | undefined>()

  const { getTopics, surveyTopics } = useGetAllSurveyRootTopicsData();

  //Update surveyResults
  const [updateSurveyResults, setUpdateSurveyResults] = useState<boolean>(false);

  useEffect(() => {
    if (updateSurveyResults) {
      getProject();
      setUpdateSurveyResults(false);
    }
  }, [updateSurveyResults]);

  //context
  const context = useContext(GeneralContext);

  useEffect(() => {
    context.setGeneralContext({ loading: true });

    Promise.all([getProject(), getUser(), getTopics()]).then(() => {
    }).finally(() => {
      context.setGeneralContext({ loading: false });
    })
    setActive("Heatmap");
  }, [id]);

  // Scroll
  const [scrollX, setScrollX] = useState<number>(0);
  const [scrollY, setScrollY] = useState<number>(0);

  useLayoutEffect(() => {
    window.scrollTo(scrollX, scrollY);
  });

  /**
   * Opens the SurveyResultDialog when a new SurveyResult has been created using the surveyTemplatePickerDialog
   * @param surveyResultId The id of the created surveyResult
   */
  const surveyResultCreatedHandler = (id: string) => {
    setScrollX(window.scrollX);
    setScrollY(window.scrollY);

    context.setGeneralContext({ loading: true });

    setSurveyResultId(id)
    setIsSurveyResultDialogOpen(true);

    Promise.all([getProject()]).then(() => {
    }).finally(() => {
      context.setGeneralContext({ loading: false });
    })
  }

  const triggerUpdateSurveyResults = () => {
    setUpdateSurveyResults(true);
  }

  const onCloseDialogHandler = (reload?: boolean) => {
    setScrollX(window.scrollX);
    setScrollY(window.scrollY);

    setIsSurveyResultDialogOpen(false);
    if (reload === true) {
      context.setGeneralContext({ loading: true });
      getProject().then(() => {
      }).finally(() => {
        context.setGeneralContext({ loading: false });
      })
    }
  }

  const closeDialogHandler = () => {
    setIsSurveyResultDialogOpen(false);
  }

  const onClickHeatMapHandler = (id: string) => {
    if (!context.generalContext.loading) {
      setScrollX(window.scrollX);
      setScrollY(window.scrollY);

      setSurveyResultId(id)
      setIsSurveyResultDialogOpen(true);
    }
  }

  const isQuotationOrProjectResponsible = (): boolean => {
    if (!project || !user) return false;

    return (user?.id === project?.quotationResponsible?.id || user?.id === project?.projectResponsible?.id)
  }

  const classes = useStyles();
  return (

    <>
      <Backdrop open={context.generalContext.loading} sx={{ color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <GeneralContext.Provider value={context}>
        <Grid container className={classes.background} sx={{ mt: 2, pt: 2, pb: 2 }}>
          <Grid item xs={12} sm={12} md={4} lg={6} xl={7} >
            <Typography
              sx={{ ml: 2, fontSize: 25 }}
              variant="h1"
              gutterBottom
              component="div"
            >
              {project?.name!}
            </Typography>
            <Typography sx={{ ml: 2 }} display="block" variant="subtitle1">
              Tilbudsnr: {project?.quoteNumber}
            </Typography>
            <Typography sx={{ ml: 2 }} display="block" variant="subtitle1">
              Sagsnr: {project?.projectNumber}
            </Typography>
          </Grid>
          <Grid item
            xs={12}
            sm={12}
            md={8}
            lg={6}
            xl={5}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <SurveyTemplatePickerDialog
              project={project}
              isQuotationOrProjectResponsible={isQuotationOrProjectResponsible()}
              surveyResultCreatedHandler={surveyResultCreatedHandler}
            />
            <ImprovementOverviewDialog
              project={project}
              isQuotationOrProjectResponsible={isQuotationOrProjectResponsible()}
              triggerUpdateSurveyResults={triggerUpdateSurveyResults}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={2}
            xl={2}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            {isSurveyResultDialogOpen === true &&
              <SurveyResultDialog
                open={isSurveyResultDialogOpen}
                surveyResultId={surveyResultId!}
                handleClose={onCloseDialogHandler}
                isQuotationOrProjectResponsible={isQuotationOrProjectResponsible()}
                closeDialog={closeDialogHandler} />}
          </Grid>
        </Grid>

        <Grid container sx={{ mt: 2 }} spacing={2}>
          <Grid item
            xs={12}
            sm={12}
            md={4}
            lg={4}>
            <Box
              sx={{
                width: '100%',
                height: 350,
                backgroundColor: '#ffffff',
              }}
            >
              <ProjectPageImportantDates project={project ?? undefined} />
            </Box>
          </Grid>
          <Grid item
            xs={12}
            sm={12}
            md={4}
            lg={4}
          >
            <Box
              sx={{
                width: '100%',
                height: 350,
                backgroundColor: '#ffffff',
              }}
            >
              <ProjectPageBudget project={project ?? undefined} />
            </Box>
          </Grid>
          <Grid item
            xs={12}
            sm={12}
            md={4}
            lg={4}>
            <Box
              sx={{
                width: '100%',
                height: 350,
                backgroundColor: '#ffffff',
              }}
            >
              <ProjectPageResponsible project={project ?? undefined} />
            </Box>
          </Grid>
        </Grid>
        <Grid container sx={{ mt: 2 }} spacing={2} alignItems="center">
          <Grid item
            xs={12}
            sm={12}
            md={8}
            lg={8}
          >
            <Typography variant="h4" gutterBottom sx={{ pt: 5 }}>
              Evalueringsresultater
            </Typography>
          </Grid>
          <Grid item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            display="flex" justifyContent="flex-end">
            <Button variant="contained" size="large" disabled={context.generalContext.loading}
              sx={{ mr: "10px" }}
              onClick={() => setActive("Heatmap")}
              startIcon={<AppsIcon />}
            >
              Heatmap
            </Button>
            <Button variant="contained" size="large" disabled={context.generalContext.loading}
              onClick={() => setActive("Trend")}
              startIcon={<TimelineIcon />}
            >
              Trend
            </Button>
          </Grid>
          <Grid item md={12} lg={12}>
            <Box>
              {active === "Heatmap" && (
                <ProjectHeatMapTable
                  surveyResults={project?.surveyResults ?? []}
                  topics={surveyTopics ?? []}
                  onClickHandler={onClickHeatMapHandler}
                  isQuotationOrProjectResponsible={isQuotationOrProjectResponsible()}
                />
              )}
              {active === "Trend" && (
                <TrendMap
                  surveyResults={project?.surveyResults ?? []}
                  topics={surveyTopics ?? []}
                  xAxisLabel={TrendMapXAxisLabel.Phase}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </GeneralContext.Provider>
    </>
  );
};

export default ProjectPage;
