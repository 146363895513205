import {
  Box,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow, Typography
} from "@mui/material";
import React, { useEffect } from "react";
import DateFormatter from "utils/formatters/DateFormatter";
import { SurveyQuestionResultDto, SurveyResultDto } from "../../model/pl-api";
import PerformCauseActionDialog from "./performCauseActionDialog";

type ImprovementOverviewElementProps = {
  surveyResult: SurveyResultDto;
  questionsUpdateHandler: () => void;
  isQuotationOrProjectResponsible: boolean;
  triggerUpdateSurveyResults: () => void;
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#D9D9D9',
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({

}));

const ImprovementOverviewElement: React.FC<ImprovementOverviewElementProps> = (
  props: ImprovementOverviewElementProps
) => {
  const [surveyQuestionResults, setQuestions] = React.useState<SurveyQuestionResultDto[]>([]);
  const [originalQuestionResults, setOriginalQuestionResults] = React.useState<SurveyQuestionResultDto[]>([]);

  //TODO: tilføj fejlbesked når et projekt ikke har data der kan vises her
  useEffect(() => {
    let questions: SurveyQuestionResultDto[] = [];
    props.surveyResult.surveyTopicResults?.forEach((x) => {
      if (x?.surveyQuestionResults != undefined)
        questions = questions.concat(x.surveyQuestionResults!);
    });
    setQuestions(questions);
    setOriginalQuestionResults(JSON.parse(JSON.stringify(questions)));
  }, [props.surveyResult]);

  const closeWithoutSaving = () => {
    setQuestions(JSON.parse(JSON.stringify(originalQuestionResults)));
  };

  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Typography variant="h6">{props.surveyResult.name}</Typography>
        </Grid>

        <Grid item sm={6}>
          <Typography variant="caption">
            Evaluering afsluttet
            {" "}
            {DateFormatter.formatToDashSeparator(props.surveyResult.completedOn)}
            {" "}
            af {props?.surveyResult?.completedBy?.name}
          </Typography>
          <Grid item sm={6}>
            {
              props?.surveyResult?.statusCode?.toString().toLowerCase() === "årsaghandlingafsluttet" ?
                (
                  <Typography variant="caption">
                    Årsag/handling afsluttet
                    {" "}
                    {DateFormatter.formatToDashSeparator(props?.surveyResult?.reasonActionCompletedOn)}
                    {" "}
                    af {props?.surveyResult?.reasonActionCompletedBy?.name ? props?.surveyResult?.reasonActionCompletedBy?.name : "-"}
                  </Typography>
                ) :
                (
                  <Typography variant="caption">
                    Årsag/handling afventer
                  </Typography>
                )
            }
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <Box display="flex" justifyContent="flex-end">
            <PerformCauseActionDialog
              isQuotationOrProjectResponsible={props.isQuotationOrProjectResponsible}
              questions={surveyQuestionResults}
              surveyResult={props.surveyResult}
              questionsUpdateHandler={props.questionsUpdateHandler}
              closeWithoutSaving={closeWithoutSaving}
              triggerUpdateSurveyResults={props.triggerUpdateSurveyResults}
            ></PerformCauseActionDialog>
          </Box>
        </Grid>
        <Grid item sm={12}>
          <TableContainer component={Paper} sx={{
            '& .MuiOutlinedInput-inputMultiline': {
              height: "100%"
            },
            width: "100%",
            maxWidth: "400"
          }}>
            <Table>
              <TableHead>
                <TableRow >
                  <StyledTableCell
                    align="left"
                    width="10%"

                    style={{
                      borderRightStyle: "solid",
                      borderRightColor: "white",
                      borderWidth: 1,
                    }}
                  >
                    Emne
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    width="20%"
                    style={{
                      borderRightStyle: "solid",
                      borderRightColor: "white",
                      borderWidth: 1,
                    }}
                  >
                    Spørgsmål
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    width="20%"
                    style={{
                      borderRightStyle: "solid",
                      borderRightColor: "white",
                      borderWidth: 1,
                    }}
                  >
                    Kommentar
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    width="5%"
                    style={{
                      borderRightStyle: "solid",
                      borderRightColor: "white",
                      borderWidth: 1,
                    }}
                  >
                    Mål KPI
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    width="5%"
                    style={{
                      borderRightStyle: "solid",
                      borderRightColor: "white",
                      borderWidth: 1,
                    }}
                  >
                    Score
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    width="20%"
                    style={{
                      borderRightStyle: "solid",
                      borderRightColor: "white",
                      borderWidth: 1,
                    }}
                  >
                    Årsag
                  </StyledTableCell>
                  <StyledTableCell align="left" width="20%">
                    Korrigerende handling
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {surveyQuestionResults.map((row) => (
                  <StyledTableRow key={row.id} sx={{

                  }}>
                    <StyledTableCell
                      align="left"
                      width="10%"
                      style={{
                        fontSize: 14,
                        borderRightStyle: "solid",
                        borderRightColor: "lightgrey",
                        borderWidth: 1,
                      }}
                    >
                      {row.surveyTopicResult?.name}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      width="20%"
                      height={0}
                      style={{
                        fontSize: 12,
                        verticalAlign: 'top',
                        borderRightStyle: "solid",
                        borderRightColor: "lightgrey",
                        borderWidth: 1,
                      }}
                    >
                      {row.question}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      width="20%"
                      style={{
                        verticalAlign: 'top',
                        fontSize: 12,
                        borderRightStyle: "solid",
                        borderRightColor: "lightgrey",
                        borderWidth: 1,
                      }}
                    >
                      {row.comment}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      width="5%"
                      style={{
                        fontSize: 12,
                        borderRightStyle: "solid",
                        borderRightColor: "lightgrey",
                        borderWidth: 1,
                      }}
                    >
                      {row.surveyTopicResult?.goalKpi}
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      width="5%"
                      style={{
                        fontSize: 12,
                        borderRightStyle: "solid",
                        borderRightColor: "lightgrey",
                        borderWidth: 1,
                      }}
                    >
                      {row.rating}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      width="20%"
                      style={{
                        verticalAlign: 'top',
                        fontSize: 12,
                        borderRightStyle: "solid",
                        borderRightColor: "lightgrey",
                        borderWidth: 1,
                      }}
                    >
                      {row.cause}
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                      width="20%"
                      style={{
                        verticalAlign: 'top',
                        fontSize: 12,
                        maxHeight: 50,
                      }}
                    >
                      {row.action}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default ImprovementOverviewElement;
