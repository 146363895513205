import { createContext } from "react";
import { FilterProps } from "./Overview";

export interface IOverviewContext {
  projectContext: FilterProps;
  setProjectContext: (projectFilter: FilterProps) => void;
}

const defaults: FilterProps = {
  projectFilter: [],
  sectionFilter: [],
  projectTypeFilter: [],
  surveyTemplateFilter: [],
  rolesFilter: [],
  periodFromFilter: undefined,
  periodToFilter: undefined,
  latestForecastLowFilter: undefined,
  latestForecastHighFilter: undefined,
};

const OverviewContext = createContext({} as IOverviewContext);

export default OverviewContext;
