import { surveyResultApi } from "api/projectlearning.api";
import { ReasonActionSaveAsDraftOrCompletedRequestDto } from "model/pl-api";
import { appInsights } from "utils/logging/appInsights";

export const saveReasonActionAsCompleted = async (
  requestDto: ReasonActionSaveAsDraftOrCompletedRequestDto
) => {
  try {
    let x = await surveyResultApi.saveReasonActionAsCompleted(requestDto);
  } catch (error: any) {
    console.log(error);
      const additionalProperties = {
      "ApplicationName": "Client App",
      "Request": "saveReasonActionAsCompleted",
      "Error": error
    };
    appInsights.trackException({exception: new Error(error)}, additionalProperties);
  }
};
