export default class SurveyStatusFormatter {
  /**
   * Formats the input to the currency format (1.000.000 -> 10.000 tkr.). "-" is returned if the param is null or undefined.
   */
  static format(status: string | undefined): string {
    if (status === "AfventerBesvarelse") return "Afventer besvarelse";
    else if (status === "AfventerÅrsagHandling")
      return "Afventer årsag / handling";
    else if (status === "ÅrsagHandlingAfsluttet")
      return "Årsag / handling afsluttet";
    else if (status === "BesvarelseAfsluttet") return "Besvarelse afsluttet";
    else if (status === undefined) return "";
    else return status;
  }
}
