import {
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@mui/material";
import {
  SurveyResultDto, SurveyRootTopicDto
} from "model/pl-api";
import { useMemo } from "react";
import HeatMapTableBody from "./HeatMapTableBody";
import HeatMapTableHeader from "./HeatMapTableHeader";

export type HeatMapTableProps = {
  topics: SurveyRootTopicDto[];
  surveyResults: SurveyResultDto[];
  loading: boolean;
};

export default function HeatMapTable(props: HeatMapTableProps) {

  const orderedTopics = useMemo(() => {
    if (!props.topics) {
      return [];
    }
    const topicsOrdered: SurveyRootTopicDto[] = props.topics.sort((a, b) =>
      (a.position ?? 0) > (b.position ?? 0) ? 1 : -1
    );
    return topicsOrdered;
  }, [props.topics]);

  return (
    <TableContainer >
      <Table aria-label="customized table" sx={{ width: '100%' }}>
        <TableHead sx={{ backgroundColor: "white" }}>
          <HeatMapTableHeader topics={orderedTopics}></HeatMapTableHeader>

        </TableHead>
        <TableBody>
          <HeatMapTableBody
            surveyResults={props.surveyResults}
            topics={orderedTopics}
            loading={props.loading}
          ></HeatMapTableBody>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
