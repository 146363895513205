import ApexCharts, { ApexOptions } from 'apexcharts';
import { SurveyResultDto } from 'model/pl-api';
import React, { useContext, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import DateFormatter from 'utils/formatters/DateFormatter';
import OverviewContext from '../../pages/OverviewContext';
import useStyles from '../../styles';
import { TrendMapProps, TrendMapXAxisLabel } from './Trendmap';

export enum TrendMapPage {
    Overview,
    Project
}

type seriesModel = {
    name: string | undefined;
    position: number | undefined;
    data: (number | undefined)[];
}[]

const TrendmapChart: React.FC<any> = (props: TrendMapProps) => {
    const ctx = useContext(OverviewContext);
    const selectedProjects = ctx.projectContext?.projectFilter;
    let displayList = props.surveyResults;
    //Sort projects
    if (selectedProjects !== undefined && selectedProjects.length > 0) {
        displayList = props?.surveyResults?.filter((x => selectedProjects.find((v) => v.id === x.project?.id)));
    }

    useEffect(() => {
        ApexCharts.exec(options.chart?.id!, 'resetSeries');

        if (props?.topics !== undefined && props?.surveyResults != undefined && props?.topics?.length > 0 && props?.surveyResults?.length)
            props?.topics?.slice(1).forEach(t => {
                ApexCharts.exec('trendmap', 'hideSeries', [t.name])
            });
    }, [props.surveyResults])

    //TODO: Split pænere? 
    //(Måske truncate? "STRESS TEST PROJECT 51 jegerlængereend25" = "STRESS TEST...rlængereend25")?
    // const formatLabel = (label: string): string | string[] | undefined => {
    //     if (label.length <= 25) return label;
    //     else {
    //         let string1 = label.substring(0, 24);
    //         let string2 = label.substring(24);
    //         let string3 = "";
    //         if (string2.length > 25) {
    //             string3 = string2.substring(24);
    //             string2 = string2.substring(0, 24);
    //         }

    //         return string3.length > 0 ? [string1, string2, string3] : [string1, string2];
    //     }
    // }

    const formatLabel = (surveyResult: SurveyResultDto, page: TrendMapPage) => {
        const evaluationName = surveyResult.name;
        const completionDate = DateFormatter.formatToDashSeparator(surveyResult.completedOn)

        if (page === TrendMapPage.Overview) {
            let projectName = surveyResult.project?.name!;
            if (projectName.length <= 25) {
                return [projectName, evaluationName + ", " + completionDate]
            } else {
                return [projectName.substring(0, 24), evaluationName + ", " + completionDate]
            }
        }

        if (page === TrendMapPage.Project) {
            return [evaluationName, completionDate]
        }
    }

    const classes = useStyles();
    const sortedSurveyResults = displayList
        .filter(sr => sr.completedOn !== undefined) // discard survey results where answers are not completed
        .sort((a, b) => a.completedOn!.getTime() - b.completedOn!.getTime());

    const series =
        (sortedSurveyResults == null) ? [] :
            props.topics.map(t => {
                return {
                    name: t.name,
                    position: t.position,
                    data: sortedSurveyResults.map(sr => sr.surveyTopicResults?.find(str => str.surveyRootTopic?.id == t.id)?.averageRating)
                }
            })

    const options: ApexOptions = {
        chart: {
            id: 'trendmap',
            type: 'line'
        },
        colors: ['#EA5B0C', '#00405b', '#006A4A', '#AB3428', '#FEBD3D', '#884C9E', '#212121', '#3D8BBF', '#9B9B9B', '#00B067', '#B1D1E7', '#F7567C', '#9388AE', '#9E9734', '#6C5A49'],
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight',
        },
        markers: {
            size: 4,
            hover: {
                size: 6
            }
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
            padding: { bottom: 40 }
        },
        xaxis: {
            categories: sortedSurveyResults
                .map(sr => props.xAxisLabel == TrendMapXAxisLabel.Project ? formatLabel(sr, TrendMapPage.Overview) : formatLabel(sr, TrendMapPage.Project)),
            labels: {
                rotate: -40,
                rotateAlways: true,
                minHeight: 100
                // trim: true
            }
        },
        yaxis: {
            min: 1,
            max: 5,
            forceNiceScale: true,
            decimalsInFloat: 0
        },
        legend: {
            position: 'left',
        }
    }

    return (
        <ReactApexChart options={options} series={series} type="line" height={450} className={classes.trendmap} />
    )
};

export default TrendmapChart;