import OverviewContext from "pages/OverviewContext";
import React, { useContext } from "react";
import OneDigitFormatter from "utils/formatters/OneDigitFormatter";
import { SurveyResultDto, SurveyRootTopicDto } from "../../model/pl-api";
import HeatMapProjectCard from "./HeatMapProjectCard";
import { HeatMapTableCell } from "./HeatMapTableCell";
import { HeatMapTableRow } from "./HeatMapTableRow";
import getTileColouring from "./HeatMapTileColouring";
export type HeatMapTableBodyProps = {
  surveyResults: SurveyResultDto[];
  topics: SurveyRootTopicDto[];
  loading: boolean;
};

export default function HeatMapTableBody(props: HeatMapTableBodyProps) {
  const ctx = useContext(OverviewContext);
  const selectedProjects = ctx.projectContext.projectFilter;
  let displayList = props.surveyResults;
  //Sort projects
  if (selectedProjects.length > 0) {
    displayList = props.surveyResults.filter((x => selectedProjects.find((v) => v.id === x.project?.id)));
  }
  return (
    <>
      {/* //Smid en sort ind før map her for date sortering */}
      {displayList
        .sort((a, b) => b.completedOn!.getTime() - a.completedOn!.getTime())
        .map((surveyResultItem) => (
          <HeatMapTableRow key={surveyResultItem.id + "TR"} sx={{ mb: 2 }}>
            <HeatMapTableCell component="th" sx={{ padding: 0 }} scope="row" key={surveyResultItem.id + "S"}>
              <HeatMapProjectCard
                project={surveyResultItem?.project!}
                surveyResult={surveyResultItem}
                loading={props.loading}
              />
            </HeatMapTableCell>
            {props.topics
              .map((rootTopic) => {
                const surveyTopicResult = surveyResultItem.surveyTopicResults
                  ?.find((topicResult) => (topicResult?.surveyRootTopic?.id === rootTopic.id))

                return (
                  <HeatMapTableCell
                    key={surveyTopicResult?.id + "S!"}
                    sx={{
                      bgcolor: getTileColouring(surveyTopicResult),
                      textAlign: 'center',
                      color: 'white',
                      fontWeight: 600
                    }}
                  >
                    {OneDigitFormatter.format(surveyTopicResult?.averageRating)}
                  </HeatMapTableCell>
                )
              })}

          </HeatMapTableRow>
        ))
      }
    </>
  );
}
