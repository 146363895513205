import { Configuration, RedirectRequest } from "@azure/msal-browser";

export const getAuthority = () => {
    return `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT}`;
};

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_AUTH_CLIENT_ID!,
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
        authority: getAuthority(),
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        loadFrameTimeout: 30000,
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: [process.env.REACT_APP_D365_BASE_URL + "//user_impersonation","openid", "profile", "User.Read"],
    authority: getAuthority(),
};