// MUI
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import OverviewContext from 'pages/OverviewContext';
import React, { useContext } from 'react';
// calculator for props
import { ForcesAndImprovementProps, forcesAndImprovementsCalculation } from './ForcesAndImprovementsCalculation';

const ForcesBox: React.FC<any> = (props: ForcesAndImprovementProps) => {
  const ctx = useContext(OverviewContext);
  const selectedProjects = ctx.projectContext.projectFilter;
  let filteredSurveyResults = props.surveyResults;
  //Sort projects
  if (selectedProjects.length > 0) {
    filteredSurveyResults = props.surveyResults.filter((x => selectedProjects.find((v) => v.id === x.project?.id)));
  }
  let calcprops = { topics: props.topics, surveyResults: filteredSurveyResults }
  const topics = forcesAndImprovementsCalculation(calcprops, true).slice(0, 3)

  return (
    <>
      <Box sx={{ width: '100%', height: '100%', bgcolor: 'background.paper' }}>
        <nav aria-label="Data box landingpage">
          <Typography variant="h6" gutterBottom sx={{ pt: 5, pl: 2 }}>
            Styrker
          </Typography>

          <List>
            {topics.map((item, index) => (
              <React.Fragment key={index + "wrapper"}>
                <ListItem key={index}>
                  <ListItemText primary={item.name} key={index + "listitem1"} />
                  <ListItemText key={index + "listitem2"}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    {
                      item.value === 0 ? "-" : Intl.NumberFormat('da-DK', {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1
                      }).format(item.value)
                    }
                  </ListItemText>
                </ListItem>
                {topics.length - 1 === index || <Divider variant="middle" key={index + "divider"} />}
              </React.Fragment>
            ))}
          </List>
        </nav>
      </Box>
    </>
  );
};

export default ForcesBox;
