import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
//MUI Component
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { SurveyTemplateDto } from "model/pl-api";
import OverviewContext from "pages/OverviewContext";
import React, { useContext, useEffect } from "react";
// VORES EGEN STYLING
import useStyles from "../../styles";
export type SurveyTemplateFiltering = {
  surveyTemplates: SurveyTemplateDto[];
  defaultFilterLoaded: boolean;
};

const SurveyTemplateSorting: React.FC<SurveyTemplateFiltering> = (props: SurveyTemplateFiltering) => {
  const classes = useStyles();
  const ctx = useContext(OverviewContext);
  const [surveyTemplateName, setSurveyTemplateName] = React.useState<string[]>([]);

  useEffect(() => {
    const filters = ctx.projectContext;
    let tempNames = filters.surveyTemplateFilter!.map((s) => s.name!);
    setSurveyTemplateName(tempNames);
  }, [props.defaultFilterLoaded]);

  const handleChange = (event: SelectChangeEvent<typeof surveyTemplateName>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const valueArray = typeof value === "string" ? value.split(",") : value;
    setSurveyTemplateName(valueArray);
    //Get values and set projects
    const filters = ctx.projectContext;
    filters.surveyTemplateFilter = valueArray.map((x) => props.surveyTemplates.find((v) => v.name === x)!)//?.id) // <-- To get id
    ctx.setProjectContext(filters);
  };

  return (
    <div>
      <FormControl className={classes.dropdown}>
        <InputLabel id="demo-multiple-checkbox-label">Evaluering</InputLabel>
        <Select
          className={classes.checkboxDropdown}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={surveyTemplateName}
          onChange={handleChange}
          input={<OutlinedInput label="Evaluering" />}
          renderValue={(selected) => "Evalueringer (" + selected.length + ")"}
        >
          {props?.surveyTemplates.map((surveyTemplate) => (
            <MenuItem key={surveyTemplate.id} value={surveyTemplate.name}>
              <Checkbox checked={surveyTemplateName.indexOf(surveyTemplate.name!) > -1} />
              <ListItemText primary={surveyTemplate.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SurveyTemplateSorting;
