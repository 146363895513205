export default class ContributionRatioFormatter {

    /**
     * Formats the input to the contributions ratio format (10,53 -> 10,5 %). "-" is returned if the param is null or undefined.
     */
    static format(num: number | undefined | null) : string {
    
        if(num === undefined || num === null) {
            return "-"
        }
        
        const numberFormatter = Intl.NumberFormat('da-DK', {
            minimumFractionDigits: 1,
            maximumFractionDigits: 1
          });
          return numberFormatter.format(num) + " %";
    }
}
