import SearchIcon from "@mui/icons-material/Search";
import { Autocomplete, CircularProgress, IconButton, Theme } from "@mui/material";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import GeneralContext from "pages/GeneralContext";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { projectApi } from "../api/projectlearning.api";
import { ProjectDto } from "../model/pl-api";

const useStyles = makeStyles((theme: Theme) => {
  return {
    searchField: {
      marginTop: 20,
    },
    textField: {
      backgroundColor: "white",
      borderRadius: 4,
    },
  };
});

const SearchBar: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [queryString, setQueryString] = useState("");
  const [loading, setLoading] = useState(false);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [projects, setProjects] = useState<ProjectDto[]>([]);
  let location = useLocation();

  const context = useContext(GeneralContext);

  const searchTermUpdatedEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newQueryString = e.target.value;
    setQueryString(newQueryString);
    if (newQueryString === "") {
      setOptionsOpen(false)
    }
  }

  const executeSearchEventButton = (e) => {
    setLoading(true);
    setOptionsOpen(true);
    projectApi.getAll(queryString).then(p => {
      setProjects(p.data ?? []);
      setLoading(false);
    })
  }

  const executeSearchEventKeyboard = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode == 13 && queryString != null) {
      setLoading(true);
      setOptionsOpen(true);
      e.preventDefault();

      projectApi.getAll(queryString).then(p => {
        setProjects(p.data ?? []);
        setLoading(false);
      })
    }
  }

  const handleClickSearch = async (projectName: string) => {
    setOptionsOpen(false);
    //Get clicked project
    const p = projects.find((prj) => prj.name === projectName);
    if (p !== null) {
      //Route to projectDetails
      if (location.pathname == '/') {
        navigate("/projektdetaljer/" + p!.id!);
        setProjects([])
        setQueryString("")
      } else {
        navigate("/projektdetaljer/" + p!.id!);
        setProjects([])
        setQueryString("")
      }
    }
  };

  const createSearchName = (project: ProjectDto) => {
    if (project.projectNumber === null && project.quoteNumber === null) {
      return project.name!;
    } else {
      return `${project.name} (Tilbudsnr: ${project.quoteNumber ?? "-"
        }, Sagsnr: ${project.projectNumber ?? "-"})`;
    }
  };

  const cleanNameForSearch = (searchName: string) => {
    if (searchName.includes("(Tilbudsnr:")) {
      var filterPQ = searchName.lastIndexOf(" (");
      return searchName.slice(0, filterPQ);
    } else {
      return searchName
    }
  }

  return (

    <Stack direction="row" >
      <Autocomplete
        onChange={(event: any, newValue: string | null | undefined) => {
          var valueList = cleanNameForSearch(newValue!)
          if (valueList != undefined) {
            handleClickSearch(valueList!);
          } else {
            handleClickSearch(newValue!);
          }
        }}
        value={null}
        disabled={context.generalContext.loading}
        blurOnSelect={true}
        className={classes.searchField}
        loading={loading}
        loadingText={"Søger..."}
        open={optionsOpen && !loading}
        id="searchBar"
        onOpen={() => {
          setOptionsOpen(false);
        }}
        onClose={() => {
          setOptionsOpen(false);
        }}
        options={projects.map((p) => createSearchName(p))}
        noOptionsText={'Ingen projekter fundet - prøv at søge på et andet filter'}
        sx={{ width: 500 }}
        renderInput={(params) => (
          <Stack direction="row">
            <TextField
              variant="filled"
              value={queryString}
              onChange={searchTermUpdatedEvent}
              onKeyDown={executeSearchEventKeyboard}
              className={classes.textField}
              {...params}
              label="Søg efter projekter"

              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
            <IconButton
              aria-label="close"
              disabled={context.generalContext.loading}
              onClick={executeSearchEventButton}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <SearchIcon />
            </IconButton>
          </Stack>
        )}
      />
    </Stack>

  );
};

export default SearchBar;
