import { surveyRootTopicApi } from "api/projectlearning.api";
import { SurveyRootTopicDto } from "model/pl-api";
import { useCallback, useState } from "react";
import { appInsights } from "utils/logging/appInsights";


const useGetAllSurveyRootTopicsHook = () => {
  const [surveyTopics, setTopic] = useState<SurveyRootTopicDto[]>();
  const [isSurveyTopicsLoading, setIsSurveyTopicsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSucces] = useState<boolean>(true)

  const getTopics = useCallback(async () => {
    try {
      setIsSurveyTopicsLoading(true);
      const surveyTopicList = await surveyRootTopicApi.getAll(
        undefined,
        undefined,
        undefined
      );
      setTopic(surveyTopicList.data!);
    } catch (error: any) {
      console.log(error);
      setIsSucces(false);
        const additionalProperties = {
        "ApplicationName": "Client App",
        "Hook": "useGetAllSurveyRootTopicsData",
        "Error": error
      };
      appInsights.trackException({exception: new Error(error)}, additionalProperties);
    } finally {
      setIsSurveyTopicsLoading(false);
      setIsSucces(true);
    }
  }, [surveyTopics] );
  return {
    surveyTopics,
    getTopics,
    isSurveyTopicsLoading,
    isSuccess
  };
};

export const useGetAllSurveyRootTopicsData = useGetAllSurveyRootTopicsHook;
