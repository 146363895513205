import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Card, CardActionArea, CardContent, Grid, Typography } from '@mui/material';
import { SurveyResultDto } from 'model/pl-api';
import GeneralContext from 'pages/GeneralContext';
import { useContext, useEffect } from 'react';
import DateFormatter from 'utils/formatters/DateFormatter';

export type ProjectHeatMapSurveyResultCardProps = {
  surveyResult: SurveyResultDto;
  onClickHandler: (id: string) => void;
  isQuotationOrProjectResponsible: boolean;
};

const ProjectHeatMapSurveyResultCard = (props: ProjectHeatMapSurveyResultCardProps) => {
  const ctx = useContext(GeneralContext);
  const status = props.surveyResult.statusCode?.toString().toLowerCase();

  useEffect(() => {
  }, [ctx.generalContext.loading]);

  const userCanNavigateToSurveyResult = (): boolean => {
    const isSurveyResultAwaitingCompletion = status === "afventerbesvarelse"

    // Survey is not awaiting competion -> any user can navigate to it
    if (!isSurveyResultAwaitingCompletion) return true;
    else {
      // User can perform the survey
      if (props.isQuotationOrProjectResponsible) return true;
      // User can not perform the survey
      else return false;
    }
  }
  return (
    <>
      {/* Styling af 'box' kan benyttes til Survey-felterne, men bør styles anderledes her */}

      <Card sx={{
        boxShadow: 0,
        borderRadius: 'none',
        height: '100px',
        '&:hover': {
          backgroundColor: ctx.generalContext.loading ? '' : '#e8e8e8',
        },

      }}>
        <CardActionArea
          sx={{
            '&:hover': {
              backgroundColor: ctx.generalContext.loading ? '' : '#F1F1F1',
            }
          }}
          onClick={() => props.onClickHandler(props.surveyResult.id!)}
          disabled={ctx.generalContext.loading && !userCanNavigateToSurveyResult()}
        >
          <Grid container columnSpacing={1}>
            <Grid item md={12} lg={12} xl={12} sx={{ mt: 1 }}>
              <CardContent>
                <Typography sx={{ fontSize: 12, whiteSpace: 'pre-line' }} gutterBottom variant="body1">
                  {props.surveyResult.name}
                </Typography>

                {status === "afventerbesvarelse" ?
                  <div>
                    <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex', justifyContent: 'space-between' }}>
                      {'Evaluering afventer'}<ArrowForwardIosIcon sx={{ color: '#383636', fontSize: '10px', }} />
                    </Typography>
                  </div> :
                  <></>
                }

                {status === "besvarelseafsluttet" ?
                  <div>
                    <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex', justifyContent: 'space-between' }}>
                      Evaluering afsluttet
                      {" "}
                      {DateFormatter.formatToDashSeparator(props?.surveyResult?.completedOn)}
                      {" "}
                      af {props?.surveyResult?.completedBy?.name}
                      <ArrowForwardIosIcon sx={{ color: '#383636', fontSize: '10px', }} />
                    </Typography>
                    <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex', justifyContent: 'space-between' }}>
                      {'Årsag/handling ikke relevant'}
                    </Typography>
                  </div> :
                  <></>
                }

                {status === "afventerårsaghandling" ?
                  <div>
                    <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex', justifyContent: 'space-between' }}>
                      Evaluering afsluttet
                      {" "}
                      {DateFormatter.formatToDashSeparator(props?.surveyResult?.completedOn)}
                      {" "}
                      af {props?.surveyResult?.completedBy?.name}
                      <ArrowForwardIosIcon sx={{ color: '#383636', fontSize: '10px', }} />
                    </Typography>
                    <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex', justifyContent: 'space-between' }}>
                      {'Årsag/handling afventer'}
                    </Typography>
                  </div> :
                  <></>
                }

                {status === "årsaghandlingafsluttet" ?
                  <div>
                    <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex', justifyContent: 'space-between' }}>
                      Evaluering afsluttet
                      {" "}
                      {DateFormatter.formatToDashSeparator(props?.surveyResult?.completedOn)}
                      {" "}
                      af {props?.surveyResult?.completedBy?.name}
                      <ArrowForwardIosIcon sx={{ color: '#383636', fontSize: '10px', }} />
                    </Typography>
                    <Typography display="block" variant="caption" color="text.secondary" sx={{ fontSize: 10, whiteSpace: 'pre-line', display: 'flex', justifyContent: 'space-between' }}>
                      Årsag/handling afsluttet
                      {" "}
                      {DateFormatter.formatToDashSeparator(props?.surveyResult?.reasonActionCompletedOn)}
                      {" "}
                      af {props?.surveyResult?.reasonActionCompletedBy?.name ? props?.surveyResult?.reasonActionCompletedBy?.name : "-"}
                    </Typography>
                  </div> :
                  <></>
                }
              </CardContent>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </>
  );
};

export default ProjectHeatMapSurveyResultCard;
