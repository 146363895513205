import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// const browserHistory = createBrowserHistory({ window });
var reactPlugin = new ReactPlugin();
var appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY,
        extensions: [reactPlugin],
        // extensionConfig: {
        //   [reactPlugin.identifier]: { history: browserHistory }
        // }
    }
});
appInsights.loadAppInsights();

export { reactPlugin, appInsights };