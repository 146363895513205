import {
  InteractionRequiredAuthError,
  PublicClientApplication,
  SilentRequest
} from "@azure/msal-browser";
import { getAuthority, msalConfig } from "./authProvider";

const pca = new PublicClientApplication(msalConfig);

export type UserInformation = {
  userName: string;
  bearer: string;
}

export async function getUserInformation(): Promise<UserInformation | null> {
  const account = pca.getActiveAccount();
  const request: SilentRequest = {
      scopes: [process.env.REACT_APP_D365_BASE_URL + "//user_impersonation","openid", "profile", "User.Read", "email"],
    account: account ?? undefined,
    authority: getAuthority(),
};
  if (account) {
    
    try {
      const { accessToken } = await pca.acquireTokenSilent(request);
      return {userName: account.username, bearer: accessToken};
    } catch (error) {
      console.log(error)
      if (error instanceof InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        pca.acquireTokenRedirect(request);
      }
    }
  }
  else{
    pca.acquireTokenRedirect(request);
  }

  return null;
}