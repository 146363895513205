import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { Button, Grid, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Paa_surveyratingrelevance, SurveyQuestionResultDto, SurveyTopicResultDto } from "model/pl-api";
import React, { useContext, useEffect, useState } from "react";
import useStyles from 'styles';
import SurveyResulContext from "./surveyResultContext";

type SurveyResultFormProps = {
    surveyQuestionResult?: SurveyQuestionResultDto;
    surveyTopicResult?: SurveyTopicResultDto;
    questionCount: number;
    currentCount: number;
    nextQuestion: () => void;
    prevQuestion: () => void;
    rerenderNavigation: () => void;
    readonly: boolean;
}

const SurveyResultForm: React.FC<SurveyResultFormProps> = (props: SurveyResultFormProps) => {
    const classes = useStyles();
    const { setSurveyResultContext, surveyResultContext } = useContext(SurveyResulContext);
    const [comment, setComment] = useState<String>(surveyResultContext?.surveyTopicResults?.find
        (x => x.id === props.surveyTopicResult?.id)?.surveyQuestionResults?.find
        (s => s.id === props.surveyQuestionResult?.id)?.comment ?? "");

    const [idea, setIdea] = useState<String>(surveyResultContext?.surveyTopicResults?.find
        (x => x.id === props.surveyTopicResult?.id)?.surveyQuestionResults?.find
        (s => s.id === props.surveyQuestionResult?.id)?.goodIdea ?? "");

    const [rating, setRating] = useState<number | undefined>(surveyResultContext?.surveyTopicResults?.find
        (x => x.id === props.surveyTopicResult?.id)?.surveyQuestionResults?.find
        (s => s.id === props.surveyQuestionResult?.id)?.rating);

    //Update view when porps are changed
    useEffect(() => {
        setComment(surveyResultContext?.surveyTopicResults?.find
            (x => x.id === props.surveyTopicResult?.id)?.surveyQuestionResults?.find
            (s => s.id === props.surveyQuestionResult?.id)?.comment ?? "");

        setIdea(surveyResultContext?.surveyTopicResults?.find
            (x => x.id === props.surveyTopicResult?.id)?.surveyQuestionResults?.find
            (s => s.id === props.surveyQuestionResult?.id)?.goodIdea ?? "");

        setRating(surveyResultContext?.surveyTopicResults?.find
            (x => x.id === props.surveyTopicResult?.id)?.surveyQuestionResults?.find
            (s => s.id === props.surveyQuestionResult?.id)?.rating);
    }, [props.surveyQuestionResult, props.surveyTopicResult])


    //Handle functions to textfields
    const handleCommentChange = (value: string) => {
        setComment(value);
        //Set comment on surveyQuestionResult
        let s = surveyResultContext;
        s!.surveyTopicResults!
            .find(t => t.id === props.surveyTopicResult!.id)!.surveyQuestionResults!
            .find(q => q.id === props.surveyQuestionResult!.id)!.comment = value;
        setSurveyResultContext(s);
    };

    const handleIdeaChange = (value: string) => {
        setIdea(value);
        //Set goodidea on surveyQuestionResult
        let s = surveyResultContext;
        s!.surveyTopicResults!
            .find(t => t.id === props.surveyTopicResult!.id)!.surveyQuestionResults!
            .find(q => q.id === props.surveyQuestionResult!.id)!.goodIdea = value;
        setSurveyResultContext(s);
    };

    const handleRatingChange = (value: number | undefined) => {
        setRating(value);
        //Set Rating on surveyQuestionResult
        let s = surveyResultContext;
        let questionRef = s!.surveyTopicResults!
            .find(t => t.id === props.surveyTopicResult!.id)!.surveyQuestionResults!
            .find(q => q.id === props.surveyQuestionResult!.id)!

        questionRef.rating = value

        if (questionRef.rating === undefined)
            questionRef.ratingRelevance = Paa_surveyratingrelevance._804240001
        else
            questionRef.ratingRelevance = Paa_surveyratingrelevance._804240000
        setSurveyResultContext(s);
        props.rerenderNavigation();

    };

    return (<>
        <Grid container spacing={3} direction="column" sx={{ mt: 0, ml: 0, justifyContent: 'start' }} >
            <Box sx={{ mr: 25, ml: 25, mt: 5 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button variant="outlined"
                        size="medium"
                        disabled={props.currentCount === 1 ? true : false}
                        onClick={props.prevQuestion}
                        sx={{
                            backgroundColor: "white",
                            paddingTop: 1,
                            paddingBottom: 1,
                            fontWeight: 600,


                        }}
                    ><ArrowBackIosIcon sx={{ paddingRight: 1 }} /> Forrige spørgsmål
                    </Button>


                    <Button variant="contained"
                        color='secondary'
                        size="medium"
                        sx={{
                            paddingTop: 1,
                            paddingBottom: 1,
                            fontWeight: 500,




                        }}
                        disabled={props.currentCount < props.questionCount ? false : true}
                        onClick={() => {
                            props.nextQuestion();
                        }}>
                        Næste spørgsmål<ArrowForwardIosIcon sx={{ paddingLeft: 1 }} />
                    </Button>
                </Box>

                <Grid item sx={{ mt: 5 }}>
                    <Typography variant="caption"

                        gutterBottom
                        component="div">
                        Spørgsmål {props.currentCount ?? "?"} af {props.questionCount}
                    </Typography>
                    <Typography variant="body1"
                        gutterBottom
                        sx={{ fontSize: 16 }}
                    >
                        Emne: {props.surveyTopicResult?.name ?? ""}
                    </Typography>

                    <Box sx={{ mt: 5, mb: 6 }}>
                        <Typography variant="body1"
                            gutterBottom
                            sx={{ mt: 2, mb: 4, fontSize: 25, fontWeight: 600 }}
                            component="div">
                            {props.surveyQuestionResult?.question ?? "Spørgsmål ikke udfyldt."}
                        </Typography>
                    </Box>
                </Grid>

                <div style={{ width: '100%' }}>
                    {/* <Typography variant="body1"
                    > Vælg et tal fra 1 til 5
                    </Typography> */}
                    <Box component="div" display='flex' sx={{ mb: 3 }}>
                        {/* Vælg rating */}
                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} >
                            <Button sx={{
                                border: '1px solid grey', borderRadius: 0, width: '100%', borderRight: 1, borderColor: 'lightgray', height: '80px', bgcolor: rating == 1 ? "#9D9D9D" : "#FEC85D",
                                '&:hover': {
                                    backgroundColor: '#9D9D9D'
                                },
                            }}
                                disabled={props.readonly}
                                onClick={() => handleRatingChange(1)}>
                                <Typography sx={{ textAlign: 'center', fontWeight: 800, color: 'white' }}>1</Typography>
                            </Button>
                            <Typography sx={{ textAlign: 'center', mt: 1 }}>Helt uenig</Typography>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Button sx={{
                                border: '1px solid grey', borderRadius: 0, width: '100%', borderRight: 1, borderColor: 'lightgray', height: '80px', bgcolor: rating == 2 ? "#9D9D9D" : "#FEBD3D",
                                '&:hover': {
                                    backgroundColor: '#9D9D9D'
                                },
                            }}
                                disabled={props.readonly}
                                onClick={() => handleRatingChange(2)}>
                                <Typography sx={{ textAlign: 'center', fontWeight: 800, color: 'white' }}>2</Typography>
                            </Button>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Button sx={{
                                border: '1px solid grey', borderRadius: 0, width: '100%', borderRight: 1, borderColor: 'lightgray', height: '80px', bgcolor: rating == 3 ? "#9D9D9D" : "#00A35F",
                                '&:hover': {
                                    backgroundColor: '#9D9D9D'
                                },
                            }}
                                disabled={props.readonly}
                                onClick={() => handleRatingChange(3)}>
                                <Typography sx={{ textAlign: 'center', fontWeight: 800, color: 'white' }}>3</Typography>
                            </Button>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2}>
                            <Button sx={{
                                border: '1px solid grey', borderRadius: 0, width: '100%', borderRight: 1, borderColor: 'lightgray', height: '80px', bgcolor: rating == 4 ? "#9D9D9D" : "#008F53",
                                '&:hover': {
                                    backgroundColor: '#9D9D9D'
                                },
                            }}
                                disabled={props.readonly}
                                onClick={() => handleRatingChange(4)}>
                                <Typography sx={{ textAlign: 'center', fontWeight: 800, color: 'white' }}>4</Typography>
                            </Button>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} >
                            <Button sx={{
                                border: '1px solid grey', borderRadius: 0, width: '100%', borderRight: 1, borderColor: 'lightgray', height: '80px', bgcolor: rating == 5 ? "#9D9D9D" : "#006A4A",
                                '&:hover': {
                                    backgroundColor: '#9D9D9D'
                                },
                            }}
                                disabled={props.readonly}
                                onClick={() => handleRatingChange(5)}>
                                <Typography sx={{ textAlign: 'center', fontWeight: 800, color: 'white' }}>5</Typography>
                            </Button>
                            <Typography sx={{ textAlign: 'center', mt: 1 }}>Helt enig</Typography>
                        </Grid>

                        <Grid item xs={12} sm={2} md={2} lg={2} xl={2} >
                            <Button sx={{
                                border: '1px solid lightGrey',
                                borderRadius: 0,
                                width: '100%',
                                height: '80px', bgcolor:
                                    rating == undefined &&
                                        (props.surveyQuestionResult?.ratingRelevance == Paa_surveyratingrelevance._804240001 ||
                                            (props.surveyQuestionResult?.ratingRelevance?.toString() === "Irrelevant")) ? "#9D9D9D" : "white",
                                '&:hover': {
                                    backgroundColor: '#9D9D9D'
                                },
                            }}
                                disabled={props.readonly}
                                onClick={() => handleRatingChange(undefined)}>
                                <Typography sx={{ textAlign: 'center' }}><DoDisturbIcon /></Typography>
                            </Button>
                            <Typography sx={{ textAlign: 'center', mt: 1 }}>Irrelevant</Typography>
                        </Grid>
                    </Box>
                </div>

                <Grid item sx={{ mb: 3, backgroundColor: 'white' }}>
                    <TextField
                        value={comment}
                        onChange={(e) => handleCommentChange(e.target.value)}
                        id="filled-multiline-static"
                        label="Kommentar"
                        multiline
                        rows={4}
                        variant="outlined"
                        disabled={props.readonly}
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item sx={{ mb: 3, backgroundColor: 'white' }}>
                    <TextField
                        value={idea}
                        onChange={(e) => handleIdeaChange(e.target.value)}
                        id="filled-multiline-static"
                        label="God idé"
                        multiline
                        rows={4}
                        variant="outlined"
                        disabled={props.readonly}
                        sx={{ width: '100%', }}
                    />
                </Grid>

            </Box>
        </Grid >
    </>)
}

export default SurveyResultForm;