import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
//MUI Component
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ProjectDto } from "model/pl-api";
import OverviewContext from "pages/OverviewContext";
import React, { useContext, useEffect } from "react";
// VORES EGEN STYLING
import useStyles from "../../styles";
export type ProjectFiltering = {
  projects: ProjectDto[];
  defaultFilterLoaded: boolean;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProjectsSorting: React.FC<ProjectFiltering> = (props: ProjectFiltering) => {
  const classes = useStyles();
  const ctx = useContext(OverviewContext);
  const [projectName, setProjectName] = React.useState<string[]>([]);

  useEffect(() => {
    const filters = ctx.projectContext;
    filters.projectFilter = projectName.map((x) => props.projects.find((p) => p.name === x)!);
    filters.projectFilter = filters.projectFilter.filter((p) => p != undefined)
    let tempNames = filters.projectFilter?.map((p) => p.name!)
    setProjectName(tempNames)

    ctx.setProjectContext(filters);
  }, [props.projects]);

  useEffect(() => {
    setProjectName([]);
  }, [props.defaultFilterLoaded])

  const handleChange = (event: SelectChangeEvent<typeof projectName>) => {
    const {
      target: { value },
    } = event;
    // On autofill we get a stringified value.
    const valueArray = typeof value === "string" ? value.split(",") : value;
    setProjectName(valueArray);

    //Get values and set projects
    const filters = ctx.projectContext;
    filters.projectFilter = valueArray.map((x) => props.projects.find((v) => v.name === x)!)//?.id) // <-- To get id

    ctx.setProjectContext(filters);
  };

  return (
    <>
      <FormControl className={classes.dropdown}>
        <InputLabel id="demo-multiple-checkbox-label">Projekter</InputLabel>
        <Select
          className={classes.checkboxDropdown}
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={projectName}
          onChange={handleChange}
          input={<OutlinedInput label="Projekter" />}
          renderValue={(selected) => "Projekter (" + selected.length + ")"}
          MenuProps={MenuProps}
        >
          {props?.projects?.map((p) => (
            <MenuItem key={p.id} value={p?.name}>
              <Checkbox checked={projectName.indexOf(p?.name!) > -1} />
              <ListItemText primary={p?.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default ProjectsSorting;
