import { Backdrop, CircularProgress, Container, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import * as React from 'react';
import { useEffect, useState } from 'react';

type ConfirmationDialogProps = {
    okayText: string;
    cancelText: string;
    content: string;
    okayHandler: (prop: any | null) => Promise<void>;
    cancelHandler: (prop: any | null) => Promise<void>;
    button: React.ReactNode;
    open: boolean
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props: ConfirmationDialogProps) => {
    const [open, setOpen] = useState(props.open);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setOpen(props.open)
    }, [props.open]);

    const handleOkay = async () => {
        try {
            setLoading(true);
            await props.okayHandler(null);
        } catch (error: any) {
            console.log(error);
        };
    };

    const handleCancel = async () => {
        try {
            setLoading(true);
            await props.cancelHandler(null);
        } catch (error: any) {
            console.log(error);
        } finally {
            setLoading(false);
        };
    };

    return (
        <div>
            <div>
                {props.button}
            </div>
            <Dialog
                open={open}
                maxWidth="xs"
            >
                {loading ?
                    <>
                        <Backdrop open={loading} sx={{ color: '#fff' }}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </>
                    :
                    <>
                        <Container>
                            <Grid container spacing={1} sx={{ p: 3 }}>
                                <Grid item sm={12} sx={{ mt: 1, }} display="flex" justifyContent="center">

                                    <Typography sx={{ textAlign: 'center' }}>{props.content}</Typography>

                                </Grid>
                                <Grid item sm={12} display="flex" justifyContent="center">
                                    <Button onClick={handleCancel}
                                        sx={{
                                            fontSize: 12,
                                            width: 200,
                                            textTransform: 'none',
                                            mt: 1,
                                        }}
                                        color="error"
                                        variant="contained"
                                    >
                                        {props.cancelText}
                                    </Button>
                                </Grid>
                                <Grid item sm={12} display="flex" justifyContent="center" sx={{ mb: 1 }}>
                                    <Button onClick={handleOkay} autoFocus
                                        sx={{
                                            fontSize: 12,
                                            width: 200,
                                            textTransform: 'none',
                                            mt: 1,
                                        }}
                                        color="success"
                                        variant="contained"
                                    >
                                        {props.okayText}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Container>
                    </>}
            </Dialog>
        </div >
    );
}

export default ConfirmationDialog;